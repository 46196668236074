import { useDispatch } from 'react-redux'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { LocationDescriptor } from 'history'
import { push, replace } from 'redux-first-history'
import { CallHistoryMethodAction } from 'redux-first-history/build/es6/actions'

import { PurchaseOrderModel } from '../api/rtk/purchaseOrderApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'
import { PATH_PURCHASE_ORDERS } from '../routes'

type GetUrlType = {
  replaceUrl?: boolean
  purchaseOrderId?: number
}

const purchaseOrderAdapter = createEntityAdapter<PurchaseOrderModel, number>({ selectId: purchaseOrder => purchaseOrder.id })
const purchaseOrderSlice = createGenericSlice({
  name: 'purchaseOrders',
  entityAdapter: purchaseOrderAdapter
})

export const purchaseOrderEntitySelectors = purchaseOrderAdapter.getSelectors<EveliaRootState>(state => state.purchaseOrders.purchaseOrders)
export const purchaseOrderActions = purchaseOrderSlice.actions

export const getPurchaseOrderUrl = (purchaseOrderId?: number) => `${PATH_PURCHASE_ORDERS}/${purchaseOrderId ?? 'new'}`

export type NavigateToPurchaseOrderType = (id?: number, options?: GetUrlType) => CallHistoryMethodAction<[location: LocationDescriptor<unknown>, state?: unknown]>
export const useNavigateToPurchaseOrder = (): NavigateToPurchaseOrderType => {
  const dispatch = useDispatch()
  return (id?: number, options?: GetUrlType) => dispatch(options?.replaceUrl ? replace(getPurchaseOrderUrl(id)) : push(getPurchaseOrderUrl(id)))
}

export const useNavigateToPurchaseOrderList = () => {
  const dispatch = useDispatch()
  return (options?: GetUrlType) => dispatch(options?.replaceUrl ? replace(PATH_PURCHASE_ORDERS) : push(PATH_PURCHASE_ORDERS))
}

export default purchaseOrderSlice
