import { capitalize } from '@evelia/helpers/helpers'
import { Action, createListenerMiddleware, isAnyOf, ThunkDispatch } from '@reduxjs/toolkit'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition
} from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import isObject from 'lodash/isObject'
import snakeCase from 'lodash/snakeCase'

import contactActions from '../../actions/contactActions'
import customerActions from '../../actions/customerActions'
import projectActions from '../../actions/projectActions'
import receiverActions from '../../actions/receiverActions'
import supplierActions from '../../actions/supplierActions'
import targetActions from '../../actions/targetActions'
import workActions from '../../actions/workActions'
import { defaultEmbeddedNormalizer, defaultNormalizer } from '../../helpers/apiHelpers'
import { addErrorNotification, addSuccessNotification } from '../../helpers/notificationHelpers'
import { CreateGenericSliceType } from '../../helpers/sliceHelpers'
import { deliveryTermActions } from '../../slices/deliveryTermSlice'
import { deliveryTypeActions } from '../../slices/deliveryTypeSlice'
import { purchaseOrderStateActions } from '../../slices/purchaseOrderStateSlice'
import { BaseIdModel } from '../types/baseModelTypes'
import { ApiRecordResponse, ApiResponse } from '.'
import { getBaseQuery } from './apiHelpers'

type ToSingularString<S extends string> = S extends `${infer P1}s` ? P1 : S

type NotificationMessages = {
  errorMessage?: string
  successMessage?: string
}

type ServerMessageError = {
  error?: { data?: { message?: string } }
}

const hasServerErrorMessage = (error: unknown): error is ServerMessageError => {
  return isObject(error) && 'error' in error &&
        isObject(error.error) && 'data' in error.error &&
        isObject(error.error.data) && 'message' in error.error.data
}

export const createNotification = async(queryFulfilled: Promise<unknown>, { errorMessage, successMessage }: NotificationMessages) => {
  try {
    await queryFulfilled
    if(successMessage) {
      addSuccessNotification(successMessage)
    }
  } catch(error: unknown) {
    const message = hasServerErrorMessage(error) ? (error?.error?.data?.message ?? errorMessage) : errorMessage
    if(message) {
      addErrorNotification(message)
    }
  }
}

interface CreateCrudApiProps<TDataModel extends BaseIdModel, TQueryArgs, TPath extends string> {
  path: TPath
  queryDefinition: Omit<QueryDefinition<TQueryArgs, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, NonNullable<unknown>, FetchBaseQueryMeta>, TPath, ApiResponse<TDataModel>, `${ToSingularString<TPath>}Api`>, 'type'>
  actions: CreateGenericSliceType<TDataModel>['actions']
  titles: {
    singular: string
    plural?: string
    genetive: string
    pluralGenetive: string
  }
}

export const createCRUDApi = <TDataModel extends BaseIdModel, TQueryArgs, TPath extends string>({
  path,
  queryDefinition,
  actions,
  titles
}: CreateCrudApiProps<TDataModel, TQueryArgs, TPath>) => {
  const singularPath = path.endsWith('s') ? path.slice(0, -1) : path
  const baseQueryPath = snakeCase(path)

  const api = createApi({
    reducerPath: `${singularPath}Api`,
    baseQuery: getBaseQuery(baseQueryPath),
    tagTypes: [path],
    endpoints: builder => ({
      // @ts-expect-error "Type '((arg: TQueryArgs) => string | FetchArgs) | undefined' is not assignable to type '(arg: TQueryArgs) => string | FetchArgs'." - can't find where I could type it as non nullable
      getRecords: builder.query<ApiResponse<TDataModel>, TQueryArgs>({
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          errorMessage: `Virhe ${titles.pluralGenetive} haussa`
        }),
        ...queryDefinition
      }),
      getRecord: builder.query<ApiRecordResponse<TDataModel>, number>({
        query: id => `/${id}`,
        providesTags: (__result, __error, id) => [{ type: path, id }],
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          errorMessage: `Virhe ${titles.genetive} haussa`
        })
      }),
      createRecord: builder.mutation<ApiRecordResponse<TDataModel>, Omit<TDataModel, 'id'>>({
        query: body => ({
          url: '',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: path }],
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} luotu`,
          errorMessage: `Virhe ${titles.genetive} luonnissa`
        })
      }),
      updateRecord: builder.mutation<ApiRecordResponse<TDataModel>, TDataModel>({
        query: body => ({
          url: `/${body.id}`,
          method: 'PUT',
          body
        }),
        invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} päivitetty`,
          errorMessage: `Virhe ${titles.genetive} päivityksessä`
        })
      }),
      deleteRecord: builder.mutation<void, BaseIdModel>({
        query: body => ({
          url: `/${body.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
        onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
          successMessage: `${capitalize(titles.singular)} poistettu`,
          errorMessage: `Virhe ${titles.genetive} poistossa`
        })
      })
    })
  })

  const listenerMiddleware = createListenerMiddleware()
  listenerMiddleware.startListening({
    matcher: isAnyOf(api.endpoints.getRecords.matchFulfilled, api.endpoints.getRecord.matchFulfilled),
    effect: (action, listenerApi) => {
      const { data, ..._embedded } = defaultEmbeddedNormalizer(action.payload ?? {})
      if(data) {
        listenerApi.dispatch(actions.fetchSuccess(data))
      }
      if(_embedded) {
        handleDispatchEmbeddedData(listenerApi.dispatch, _embedded)
      }
    }
  })
  listenerMiddleware.startListening({
    matcher: api.endpoints.createRecord.matchFulfilled,
    effect: (action, listenerApi) => {
      const data = defaultNormalizer(action.payload ?? {})
      if(data) {
        listenerApi.dispatch(actions.createSuccess(data))
      }
    }
  })
  listenerMiddleware.startListening({
    matcher: api.endpoints.updateRecord.matchFulfilled,
    effect: (action, listenerApi) => {
      const data = defaultNormalizer(action.payload ?? {})
      if(data) {
        listenerApi.dispatch(actions.updateSuccess(data))
      }
    }
  })
  listenerMiddleware.startListening({
    matcher: api.endpoints.deleteRecord.matchFulfilled,
    effect: (action, listenerApi) => {
      const id = action.meta.arg.originalArgs?.id
      if(id) {
        listenerApi.dispatch(actions.deleteSuccess({ id }))
      }
    }
  })

  const useMutationsHook = () => {
    const [create] = api.useCreateRecordMutation()
    const [update] = api.useUpdateRecordMutation()
    const [remove] = api.useDeleteRecordMutation()
    return { create, update, remove }
  }

  return { api, listenerMiddleware, useMutationsHook }
}

export const handleDispatchEmbeddedData = (dispatch: ThunkDispatch<unknown, unknown, Action>, _embedded: ApiResponse<unknown>['_embedded']) => {
  dispatch(supplierActions.fetchSuccess(_embedded.suppliers ?? []))
  dispatch(customerActions.fetchSuccess(_embedded.customers ?? []))
  dispatch(projectActions.fetchSuccess(_embedded.projects ?? []))
  dispatch(targetActions.fetchSuccess(_embedded.targets ?? []))
  dispatch(workActions.fetchSuccess((_embedded.work ?? []) as unknown[]))
  dispatch(contactActions.fetchSuccess(_embedded.contacts ?? []))
  dispatch(deliveryTypeActions.fetchSuccess(_embedded.deliveryTypes ?? []))
  dispatch(deliveryTermActions.fetchSuccess(_embedded.deliveryTerms ?? []))
  dispatch(purchaseOrderStateActions.fetchSuccess(_embedded.purchaseOrderStates ?? []))
  dispatch(receiverActions.fetchSuccess(_embedded.receivers ?? []))
}
