import { createEntityAdapter } from '@reduxjs/toolkit'

import { DeliveryTypeModel } from '../api/rtk/deliveryTypeApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const deliveryTypeAdapter = createEntityAdapter<DeliveryTypeModel, number>({ selectId: deliveryType => deliveryType.id })

const deliveryTypeSlice = createGenericSlice({
  name: 'deliveryTypes',
  entityAdapter: deliveryTypeAdapter
})

export const deliveryTypeEntitySelectors = deliveryTypeAdapter.getSelectors<EveliaRootState>(state => state.deliveryTypes)
export const deliveryTypeActions = deliveryTypeSlice.actions

export default deliveryTypeSlice
