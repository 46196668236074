import React from 'react'
import get from 'lodash/get'
import isString from 'lodash/isString'
import reduxCrud from 'redux-crud-mb'

export type Required<T> = {
  [P in keyof T]-?: T[P];
}

export type WithNulls<T> = {
  [P in keyof T]: T[P] | null
}

export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

export type OmitNulls<T> = {
  [P in keyof T]: Exclude<T[P], null>
}

export type WithSubRows<T> = T & { subRows: WithSubRows<T>[] }
export type Modify<T, R> = Omit<T, keyof R> & R;

export type ActionCreatorBase = {
  searchRequest: (searchTerm: unknown, data: NonNullable<unknown>) => {
    searchTerm: unknown
    data: NonNullable<unknown>
    type: string
  }
  // Type more actions if needed
} & ReturnType<typeof reduxCrud.actionCreatorsFor>

// https://stackoverflow.com/questions/57477395/typescript-generic-class-equivalent-for-react-memo
export const ReactMemoTypeForward: <T>(component: T) => T = React.memo

// Safely try to get a value from a object that typescript doesn't know about, useful eg. with non-typed data-table meta's
export const tryGetValue = <TReturn>(obj?: unknown, ...keys: (string | number)[]): TReturn | undefined => {
  for(const key of keys) {
    if(obj == null) {
      return undefined
    }
    if(isString(key)) {
      obj = get(obj, key)
    } else {
      obj = obj[key]
    }
  }
  return obj as TReturn
}

// Temporary placeholder type that can be used instead of 'typeof' before common-constants are typed and actual 'typeof' can be used instead
export type TODOTypeof<T> = T
