import { faCheck } from '@fortawesome/free-solid-svg-icons'

import IconButton, { IconButtonProps } from './IconButton'

const checkProps = props => {
  if(import.meta.env.MODE === 'development' && props.onClick == null && props.isBusy == null) {
    console.warn('SaveButton tarvitsee propseina joko onClick-käsittelijän tai isBusyn')
  }
  return false
}

interface SaveButtonProps extends Omit<IconButtonProps, 'type' | 'color'> {
}

const SaveButton = (props: SaveButtonProps) => {
  checkProps(props)
  return (
    <IconButton
      type='submit'
      color='success'
      testId={props.testId ?? 'saveButton'}
      {...props}
      icon={props.icon || faCheck}
    />
  )
}

export default SaveButton
