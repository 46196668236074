import { ChangeEvent, useState } from 'react'
import DateMomentUtils from '@date-io/moment'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import get from 'lodash/get'

import useToggle from '../../hooks/useToggle'
import FormInputGroupWrapper from './FormInputGroupWrapper'
import FormInputWrapper from './FormInputWrapper'
import FormMaterialUiInputAdapter from './FormMaterialUiInputAdapter'
import { parseForForm, stringToMoment } from './hookFormHelpers'

export interface FormPlainDateInputProps
  extends Pick<React.ComponentProps<typeof FormInputWrapper>,
  'name' |
  'label' |
  'inputOnly' |
  'required' |
  'formGroupClassName' |
  'labelClassName' |
  'tooltip' |
  'helpText'>,
  Pick<React.ComponentProps<typeof FormInputGroupWrapper>,
  'append' |
  'prepend' |
  'name' |
  'errors' |
  'tooltipError' |
  'isCustomAddon' |
  'positionFixed' > {
  value?: boolean | number | string
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  onValueChange?: (ev: ChangeEvent<HTMLInputElement>) => void
  inputProps?: Partial<React.ComponentProps<typeof KeyboardDatePicker>>
  disabled?: boolean
  readOnly?: boolean
  testId?: string
  placeholder?: string
  invalid?: boolean
}
const FormPlainDateInput = ({
  name,
  errors,
  label,
  inputOnly,
  required,
  disabled,
  prepend,
  append,
  tooltipError,
  value,
  onChange,
  onValueChange,
  inputProps,
  invalid
}: FormPlainDateInputProps) => {
  const [pickerError, setPickerError] = useState()
  const [isOpen, { enable, disable }] = useToggle()

  const inputError = pickerError
    ? { message: pickerError }
    : get(errors, name)

  if(inputError?.message === 'Date should not be before minimal date') {
    inputError.message = 'Päivämäärän oltava tulevaisuudessa'
  }

  const combinedErrors = inputError
    ? { [name]: inputError }
    : null

  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <KeyboardDatePicker
        id={name}
        value={stringToMoment(value)}
        name={name}
        onChange={momentDate => {
          const date = parseForForm(momentDate)
          onChange?.(date)
          onValueChange?.(date)
        }}
        // @ts-expect-error dont' know how to solve
        TextFieldComponent={FormMaterialUiInputAdapter}
        autoOk
        todayLabel='Tänään'
        clearLabel='Tyhjennä'
        cancelLabel='Peruuta'
        invalidDateMessage='Virheellinen muoto'
        format='DD.MM.YYYY'
        open={isOpen}
        onOpen={enable}
        onClose={disable}
        clearable={!inputProps?.readOnly}
        // @ts-expect-error returns ReactNode which is always string
        onError={setPickerError}
        label={label}
        inputOnly={inputOnly}
        required={required}
        data-testid={name}
        setOpen={enable}
        invalid={invalid || !!combinedErrors}
        disabled={disabled}
        errors={combinedErrors}
        tooltipError={tooltipError}
        prepend={prepend}
        append={append}
        isDate
        {...inputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default FormPlainDateInput
