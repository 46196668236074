import { createEntityAdapter } from '@reduxjs/toolkit'

import { PurchaseOrderStateModel } from '../api/rtk/purchaseOrderStateApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const purchaseOrderStateAdapter = createEntityAdapter<PurchaseOrderStateModel, number>({ selectId: purchaseOrderState => purchaseOrderState.id })

const purchaseOrderStateSlice = createGenericSlice({
  name: 'purchaseOrderStates',
  entityAdapter: purchaseOrderStateAdapter
})

export const purchaseOrderStateEntitySelectors = purchaseOrderStateAdapter.getSelectors<EveliaRootState>(state => state.purchaseOrders.purchaseOrderStates)
export const purchaseOrderStateActions = purchaseOrderStateSlice.actions

export default purchaseOrderStateSlice
