import { createEntityAdapter } from '@reduxjs/toolkit'

import { DeliveryTermModel } from '../api/rtk/deliveryTermApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const deliveryTermAdapter = createEntityAdapter<DeliveryTermModel, number>({ selectId: deliveryTerm => deliveryTerm.id })

const deliveryTermSlice = createGenericSlice({
  name: 'deliveryTerms',
  entityAdapter: deliveryTermAdapter
})

export const deliveryTermEntitySelectors = deliveryTermAdapter.getSelectors<EveliaRootState>(state => state.deliveryTerms)
export const deliveryTermActions = deliveryTermSlice.actions

export default deliveryTermSlice
