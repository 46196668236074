import { parseTemplate } from 'url-template'

import constants, { actionTypes } from '../constants'
import {
  actionCreatorsFor,
  errorRecords,
  request,
  startRecords,
  success,
  successRecords
} from '../helpers/actionHelpers'
import { PATH_PROJECTS } from '../routes'
import { navigateTo } from './navigateActions'
import tableActions from './tableActions'

const baseUrl = `${PATH_PROJECTS}{/projectId}{/subroute}{/subitemId}`
const baseTemplate = parseTemplate(baseUrl)
export const getUrl = (params = {}) => baseTemplate.expand(params)
const subprojectRoute = 'subprojects'
const subinstalmentRoute = 'instalments'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.PROJECTS, { budgetFetch: false, budgetByDenominationFetch: false, fetchExtraInfo: true })

const projectNavigateTo = (replaceUrl, data) => navigateTo(getUrl(data), replaceUrl)

const additionalActionCreators = {
  files: actionCreatorsFor(constants.actionKeys.PROJECT_FILES),
  memos: actionCreatorsFor(constants.actionKeys.PROJECT_MEMOS),
  invoices: actionCreatorsFor(constants.actionKeys.PROJECT_INVOICES),
  inboundInvoices: actionCreatorsFor(constants.actionKeys.PROJECT_INBOUND_INVOICES),
  employees: actionCreatorsFor(constants.actionKeys.PROJECT_EMPLOYEES),
  additionalPersons: actionCreatorsFor(constants.actionKeys.PROJECT_ADDITIONAL_PERSONS),
  contacts: actionCreatorsFor(constants.actionKeys.PROJECT_CONTACTS),
  projectExtraBudgets: actionCreatorsFor(constants.actionKeys.PROJECT_EXTRA_BUDGETS),
  projectExtraExpenses: actionCreatorsFor(constants.actionKeys.PROJECT_EXTRA_EXPENSES),
  projectDenominationBudgets: actionCreatorsFor(constants.actionKeys.PROJECT_DENOMINATION_BUDGETS),
  navigateTo: (projectId, replaceUrl, initNewSubproject) =>
    projectNavigateTo(replaceUrl,
      {
        projectId,
        subroute: initNewSubproject ? subprojectRoute : null,
        subitemId: initNewSubproject ? 'new' : null
      }),
  navigateToBase: replaceUrl => projectNavigateTo(replaceUrl),
  navigateToInstalment: (projectId, instalmentId, replaceUrl) =>
    projectNavigateTo(replaceUrl, { projectId, subroute: subinstalmentRoute, subitemId: instalmentId }),
  doSubItemFetchAction: request(actionTypes.PROJECTS_FETCH_ACTION_REQUEST),
  doApiAction: request(actionTypes.PROJECT_API_ACTION_REQUEST),
  fetchExtraInfoSuccess: success(baseActionCreators.actionTypes.fetchExtraInfoSuccess),

  budgetFetchStart: startRecords(baseActionCreators.actionTypes.budgetFetchStart),
  budgetFetchSuccess: successRecords(baseActionCreators.actionTypes.budgetFetchSuccess),
  budgetFetchError: errorRecords(baseActionCreators.actionTypes.budgetFetchError),

  budgetByDenominationFetchStart: startRecords(baseActionCreators.actionTypes.budgetByDenominationFetchStart),
  budgetByDenominationFetchSuccess: successRecords(baseActionCreators.actionTypes.budgetByDenominationFetchSuccess),
  budgetByDenominationFetchError: errorRecords(baseActionCreators.actionTypes.budgetByDenominationFetchError),
  createRTVReport: request(actionTypes.PROJECTS_CREATE_RTV_REPORT),
  tableActions: tableActions(constants.actionKeys.PROJECTS),
  generateReport: request(actionTypes.PROJECT_GENERATE_REPORT_REQUEST)
}

const projectActions = { ...baseActionCreators, ...additionalActionCreators }

export default projectActions
