import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import denominationActions from '../../actions/denominationActions'
import { getBaseQuery } from './apiHelpers'

export type DenominationModel = {
  id: number
  denominationPacketId: number
  denominationGroupId: number
  name: string
  number: string
  denominationNumber: string
  type: 'work' | 'work_record' | null
}

export const denominationApi = createApi({
  reducerPath: 'denominatioApi',
  baseQuery: getBaseQuery('denominations'),
  endpoints: builder => ({
    getProductDenomination: builder.query<{ record: DenominationModel }, number>({
      query: query => `/product_denomination/${query}`
    })
  })
})

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: denominationApi.endpoints.getProductDenomination.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { record } = action?.payload || {}
    listenerApi.dispatch(denominationActions.fetchSuccess([record]))
  }
})

export const { useGetProductDenominationQuery, useLazyGetProductDenominationQuery } = denominationApi
