import PropTypes from 'prop-types'

import {
  denominationTypes,
  projectExtraExpenseTypes,
  systemCustomerAnnualInvoicingTypes,
  vatTypes,
  workRowTypes
} from './constants'

export const shapeOrEmpty = shape => PropTypes.oneOfType([
  shape,
  PropTypes.shape({})
])

export const oneOfPrimitivesPropType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.symbol
])

export const vatTypePropType = PropTypes.oneOf(Object.values(vatTypes))

export const customerPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nameExtension: PropTypes.string,
  nameExtension2: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  ovt: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  vatType: vatTypePropType,
  paymentTerm: PropTypes.number,
  isRemindersProhibited: PropTypes.bool.isRequired,
  customerAdditionalGroupIds: PropTypes.arrayOf(PropTypes.number),
  workFormTypeId: PropTypes.number
})

export const customerGroupPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired,
  interest: PropTypes.number,
  pricingRuleSetId: PropTypes.number,
  paymentTerm: PropTypes.number,
  workFormTypeId: PropTypes.number
})

export const targetPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nameExtension: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  workFormTypeId: PropTypes.number
})

export const contactPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  notes: PropTypes.string
})

export const workPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  targetId: PropTypes.number,
  customerId: PropTypes.number,
  projectId: PropTypes.number,
  yourReference: PropTypes.string,
  ourReference: PropTypes.string,
  isVatPrimary: PropTypes.bool,
  state: PropTypes.number.isRequired,
  workNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  workTypeId: PropTypes.number,
  supervisorId: PropTypes.number,
  isInterrupted: PropTypes.bool.isRequired,
  interruptReason: PropTypes.string,
  isClosed: PropTypes.bool.isRequired,
  notInvoisable: PropTypes.bool.isRequired,
  siteNumber: PropTypes.string,
  mark: PropTypes.string,
  customerCostCentre: PropTypes.string,
  contactId: PropTypes.number,
  confirmationRequested: PropTypes.bool.isRequired,
  confirmedAt: PropTypes.string,
  confirmedBy: PropTypes.number,
  startDate: PropTypes.string,
  vatType: vatTypePropType,
  pricingRuleSetId: PropTypes.number,
  isTemplate: PropTypes.bool.isRequired,
  type: PropTypes.string,
  createdBy: PropTypes.number,
  isRecurring: PropTypes.bool,
  defaultFileEmployeeLevelId: PropTypes.number
})

export const instalmentPropType = workPropType

export const workRowPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  workId: PropTypes.number.isRequired,
  preventInvoicing: PropTypes.bool.isRequired,
  productId: PropTypes.number,
  warehouseId: PropTypes.number,
  name: PropTypes.string,
  nameExtension: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.number,
  vatPrice: PropTypes.number,
  purchasePrice: PropTypes.number,
  productNumber: PropTypes.string,
  discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  vatClassId: PropTypes.number,
  vatCodeId: PropTypes.number,
  deliveryDate: PropTypes.string,
  comment: PropTypes.string,
  normHour: PropTypes.number,
  normHourPrice: PropTypes.number,
  productTaskPacketIndustryName: PropTypes.string,
  productTaskDescription: PropTypes.string,
  state: PropTypes.string,
  productLineCode: PropTypes.string,
  accountNumber: PropTypes.number
})

export const workEmployeePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  workId: PropTypes.number.isRequired,
  employeeId: PropTypes.number.isRequired
})

export const workTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired
})

export const supplierInfoPropType = PropTypes.shape({
  supplierId: PropTypes.number.isRequired,
  purchasePrice: PropTypes.number.isRequired
})

export const productPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nameExtension: PropTypes.string,
  productNumber: PropTypes.string.isRequired,
  productLineId: PropTypes.number.isRequired,
  productLineCode: PropTypes.string.isRequired,
  supplierInfos: PropTypes.arrayOf(supplierInfoPropType),
  defaultVatClassId: PropTypes.number
})

export const productLinePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired
})

export const vatRatePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  reversed: PropTypes.bool.isRequired,
  default: PropTypes.bool.isRequired
})

export const vatClassPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  identifier: PropTypes.string,
  isReversed: PropTypes.bool,
  isGeneric: PropTypes.bool,
  isVatless: PropTypes.bool
})

export const invoicePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  notes: PropTypes.string,
  paymentTerm: PropTypes.number.isRequired,
  interest: PropTypes.number.isRequired,
  createdAt: PropTypes.string,
  invoiceDate: PropTypes.string,
  invoiceNumber: PropTypes.number,
  invoiceRows: PropTypes.array,
  defaultInterest: PropTypes.number,
  defaultPaymentTerm: PropTypes.number,
  isVatPrimary: PropTypes.bool,
  workId: PropTypes.number,
  work: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    yourReference: PropTypes.string,
    ourReference: PropTypes.string
  }),
  targetId: PropTypes.number,
  target: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string
  }),
  customerId: PropTypes.number,
  customer: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    businessId: PropTypes.string,
    iban: PropTypes.string
  }),
  employeeId: PropTypes.number,
  employee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  }),
  vatPriceSum: PropTypes.number,
  priceSum: PropTypes.number,
  discountVatPrice: PropTypes.number,
  discountPriceSum: PropTypes.number
})

const minimalEmployeeProperties = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isActive: PropTypes.bool,
  isNotResource: PropTypes.bool,
  isNonBillable: PropTypes.bool,
  activeUntil: PropTypes.string
}

const defaultEmployeeProperties = {
  ...minimalEmployeeProperties,
  email: PropTypes.string.isRequired,
  employeeLevelId: PropTypes.number,
  phoneNumber: PropTypes.string,
  defaultWarehouseId: PropTypes.number,
  defaultCostCentreId: PropTypes.number,
  primaryEmployeeRoleId: PropTypes.number,
  employeeRoleIds: PropTypes.arrayOf(PropTypes.number),
  defaultSystemCustomerId: PropTypes.number,
  maximumInboundInvoiceApprovalSum: PropTypes.number,
  workTimeStart: PropTypes.string,
  workTimeEnd: PropTypes.string
}
export const minimalEmployeePropType = PropTypes.shape(minimalEmployeeProperties)
export const employeePropType = PropTypes.shape(defaultEmployeeProperties)

export const employeePrivatePropType = PropTypes.shape({
  ...defaultEmployeeProperties,
  personalIdentityCode: PropTypes.string,
  taxNumber: PropTypes.string,
  personNumber: PropTypes.string,
  dateOfBirth: PropTypes.string
})

export const employeeSystemCustomerPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
})

export const employeeLevelPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  accessLevel: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
})

export const employeeLicencePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
  validFrom: PropTypes.string,
  validUntil: PropTypes.string,
  employeeLicenceTypeId: PropTypes.number
})

export const employeeLicenceTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
})

export const employeeRolePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  // color: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired
})

export const authorPropType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  displayName: PropTypes.string
})

export const systemCustomerDetailsPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  reference: PropTypes.string,
  website: PropTypes.string,
  phoneNumber: PropTypes.string
})

export const systemCustomerSettingsPropType = PropTypes.shape({
  supplierPriority: PropTypes.arrayOf(PropTypes.number),
  defaultVatClassId: PropTypes.number.isRequired,
  paymentTerm: PropTypes.number.isRequired,
  interest: PropTypes.number.isRequired,
  isVatPrimary: PropTypes.bool.isRequired,
  nextCustomerNumber: PropTypes.number,
  nextInvoiceNumber: PropTypes.number,
  nextTargetNumber: PropTypes.number,
  nextWorkNumber: PropTypes.number,
  nextInboundInvoiceNumber: PropTypes.number,
  nextProjectNumber: PropTypes.number,
  nextPurchaseOrderNumber: PropTypes.number,
  workConfirmationRequired: PropTypes.bool
})

export const systemCustomerMaventaPropType = PropTypes.shape({
  apiKey: PropTypes.string,
  companyUuid: PropTypes.string
})

export const systemCustomerEmailSettingPropType = PropTypes.shape({
  sendBccToSelf: PropTypes.bool.isRequired,
  sendCopyToContact: PropTypes.bool.isRequired,
  sendCalendarEventForWorkEmployee: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    subject: PropTypes.string,
    message: PropTypes.string
  }),
  work: PropTypes.shape({
    subject: PropTypes.string,
    message: PropTypes.string
  }),
  invitation: PropTypes.shape({
    subject: PropTypes.string,
    message: PropTypes.string
  })
})

export const systemCustomerBasicSettingPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  postalCode: PropTypes.string,
  businessId: PropTypes.string,
  reference: PropTypes.string,
  website: PropTypes.string,
  phoneNumber: PropTypes.string,
  industry: PropTypes.number,
  themeColor: PropTypes.string,
  effectColor: PropTypes.string,
  companyLogo: PropTypes.string,
  referenceNumber: PropTypes.string,
  updatedAt: PropTypes.string
})

export const systemCustomerPropType = PropTypes.shape({
  details: systemCustomerDetailsPropType.isRequired,
  settings: systemCustomerSettingsPropType.isRequired,
  maventa: systemCustomerMaventaPropType.isRequired,
  emailSettings: systemCustomerEmailSettingPropType
})

export const accountPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  accountType: PropTypes.number.isRequired,
  vatCodeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
})

export const defaultAccountPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  accountType: PropTypes.number.isRequired,
  vatClassId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired
})

export const accountingTypePropType = PropTypes.number

export const accountingConstantsPropType = PropTypes.shape({
  sales: accountingTypePropType,
  purchases: accountingTypePropType,
  balance: accountingTypePropType
})

export const filePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  filePath: PropTypes.string.isRequired,
  employeeLevelId: PropTypes.number,
  createdBy: PropTypes.number,
  createdAt: PropTypes.string
})

export const relationFilePropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      filePropType,
      PropTypes.shape({
        relationId: PropTypes.number.isRequired,
        relationNumber: PropTypes.number,
        description: PropTypes.string,
        children: PropTypes.arrayOf(filePropType)
      })
    ])
  )
})

export const supplierPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  ovt: PropTypes.string.isRequired
})

export const inboundInvoicePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired
})

export const inboundInvoiceRowPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  inboundInvoiceId: PropTypes.number.isRequired,
  workId: PropTypes.number,
  workRowId: PropTypes.number,
  productNumber: PropTypes.string,
  position: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceSum: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  vatPrice: PropTypes.number.isRequired,
  vatPriceSum: PropTypes.number.isRequired,
  vatCodeId: PropTypes.number,
  unit: PropTypes.string,
  comment: PropTypes.string,
  vatType: vatTypePropType
})

export const receiverPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  businessId: PropTypes.string,
  ovt: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  ibans: PropTypes.array,
  tags: PropTypes.array,
  defaultAccountNumber: PropTypes.number,
  ispaymentProhibited: PropTypes.bool,
  creditInboundInvoiceEmail: PropTypes.string
})

export const packetIndustryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  normHourPrice: PropTypes.number.isRequired
})

export const productTaskPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
  normHour: PropTypes.number.isRequired,
  packetIndustryId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  _embedded: PropTypes.shape({
    packetIndustry: packetIndustryPropType
  })
})

export const packetPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  packetNumber: PropTypes.string.isRequired,
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string,
  isEditable: PropTypes.bool
})

export const emailFormModelPropType = PropTypes.shape({
  receivers: PropTypes.arrayOf(PropTypes.string),
  cc: PropTypes.arrayOf(PropTypes.string),
  bcc: PropTypes.arrayOf(PropTypes.string),
  subject: PropTypes.string,
  message: PropTypes.string
})

export const bankAccountPropType = PropTypes.shape({
  iban: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bic: PropTypes.string.isRequired,
  account: PropTypes.number.isRequired,
  serviceIdentifier: PropTypes.string
})

export const pricingRulePropType = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  pricingRuleSetId: PropTypes.number,
  productLineId: PropTypes.number,
  pricingRuleType: PropTypes.number,
  useMostExpensive: PropTypes.bool,
  discount: PropTypes.number,
  priceMultiplier: PropTypes.number,
  grossProfit: PropTypes.number
})

export const pricingRuleSetPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired
})

export const pricingRuleFallbackPropType = PropTypes.shape({
  id: PropTypes.number,
  useAlwaysInboundInvoicePricing: PropTypes.bool.isRequired,
  inboundInvoicePricingType: PropTypes.number.isRequired,
  inboundInvoicePricingValue: PropTypes.number.isRequired,
  inboundInvoiceRowNamingType: PropTypes.number.isRequired,
  inboundInvoiceRowCreationType: PropTypes.number.isRequired
})

export const sagaErrorPropType = PropTypes.shape({
  status: PropTypes.number,
  message: PropTypes.string,
  url: PropTypes.string
})

export const invitationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  unsubscribed: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired
})

export const textTemplatesPropType = PropTypes.shape({
  emailInvitationTemplate: PropTypes.string.isRequired,
  emailInvitationSubject: PropTypes.string.isRequired,
  reminderInvoiceComment: PropTypes.string.isRequired,
  secondReminderInvoiceComment: PropTypes.string.isRequired
})

export const tableOptionPropType = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  orderBy: PropTypes.arrayOf(PropTypes.string),
  sortOrder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOf(['ASC', 'DESC'])), PropTypes.oneOf(['ASC', 'DESC'])]),
  fixedFilters: PropTypes.object,
  filters: PropTypes.object,
  limit: PropTypes.number,
  page: PropTypes.number
})

export const workRecordProductPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  productLineId: PropTypes.number.isRequired,
  productNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  recordType: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  isBillable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isDescriptionRequired: PropTypes.bool.isRequired,
  rounding: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  employeeRoleIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  defaultVatClassId: PropTypes.number.isRequired,
  defaultSalesAccountNumber: PropTypes.number,
  isDisabled: PropTypes.bool
})

export const selectOptionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]),
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ]).isRequired,
    className: PropTypes.string
  }))

export const workRecordPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  employeeId: PropTypes.number.isRequired,
  workRecordProductId: PropTypes.number,
  workId: PropTypes.number,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
  startDateTime: PropTypes.string,
  endTime: PropTypes.string,
  endDate: PropTypes.string,
  endDateTime: PropTypes.string,
  isExplicitEndDate: PropTypes.bool,
  isExplicitBillingAmount: PropTypes.bool,
  amount: PropTypes.number,
  billingAmount: PropTypes.number,
  isRunning: PropTypes.bool,
  workRowId: PropTypes.number
})

export const salaryTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  salaryTypeNumber: oneOfPrimitivesPropType,
  price: PropTypes.number.isRequired
})

export const salaryEventPropType = PropTypes.shape({
  id: PropTypes.number,
  _id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  employeeId: PropTypes.number.isRequired,
  approvedBy: PropTypes.number,
  workRecordId: PropTypes.number.isRequired,
  salaryTypeId: PropTypes.number.isRequired,
  salaryTypeName: PropTypes.string,
  salaryTypeNumber: oneOfPrimitivesPropType,
  price: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  priceSum: PropTypes.number.isRequired
})

export const receiptPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  receiptSeller: PropTypes.string,
  description: PropTypes.string,
  receiptDate: PropTypes.string,
  paymentMethod: PropTypes.number,
  sum: PropTypes.number
})

export const receiptReportPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  employeeId: PropTypes.number,
  accounting: PropTypes.array,
  receiptSeller: PropTypes.string,
  description: PropTypes.string,
  receiptDate: PropTypes.string,
  receiptNumber: PropTypes.number,
  paymentMethod: PropTypes.number,
  sum: PropTypes.number
})

export const cashReceiptPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  employeeId: PropTypes.number,
  customerId: PropTypes.number,
  workId: PropTypes.number,
  createdAt: PropTypes.string,
  cashReceiptNumber: PropTypes.number,
  paymentMethod: PropTypes.string,
  printingMethod: PropTypes.string,
  customerSum: PropTypes.number,
  walkInCustomerName: PropTypes.string,
  comment: PropTypes.string
})

export const cashReceiptRowPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  cashReceiptId: PropTypes.number,
  vatClassId: PropTypes.number.isRequired,
  vatCodeId: PropTypes.number.isRequired,
  productId: PropTypes.number,
  workRecordProductId: PropTypes.number,
  name: PropTypes.string.isRequired,
  nameExtension: PropTypes.string,
  unit: PropTypes.string,
  count: PropTypes.number,
  price: PropTypes.number,
  vatPrice: PropTypes.number,
  priceSum: PropTypes.number,
  vatPriceSum: PropTypes.number,
  discount: PropTypes.number
})

export const cashReceiptFieldArrayPropType = PropTypes.shape({
  fields: PropTypes.arrayOf(cashReceiptRowPropType).isRequired,
  append: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired
})

export const apiFormPropType = PropTypes.shape({
  control: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
})

export const nativeFilePropType = PropTypes.shape({
  base64: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string
})

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
])

export const projectPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  projectNumber: PropTypes.number,
  name: PropTypes.string.isRequired,
  estimatedSales: PropTypes.number,
  estimatedCost: PropTypes.number,
  estimatedHours: PropTypes.number,
  estimatedSalaryPrices: PropTypes.number,
  hasSubItems: PropTypes.bool
})

const workRowTypePropType = PropTypes.shape({
  count: PropTypes.number.isRequired,
  priceSum: PropTypes.number.isRequired,
  vatPriceSum: PropTypes.number.isRequired,
  purchasePriceSum: PropTypes.number.isRequired
})

export const workCommentRowTypePropType = PropTypes.shape({
  count: PropTypes.number.isRequired
})

export const workRowStateItemPropType = PropTypes.shape({
  [workRowTypes.WORK_ROW_TYPE_PRODUCT]: workRowTypePropType,
  [workRowTypes.WORK_ROW_TYPE_COMMENT]: workCommentRowTypePropType,
  [workRowTypes.WORK_ROW_TYPE_WORK_RECORD]: workRowTypePropType,
  [workRowTypes.WORK_ROW_TYPE_MACHINE_RECORD]: workRowTypePropType,
  [workRowTypes.WORK_ROW_TYPE_INBOUND_INVOICE]: workRowTypePropType
})

export const inboundInvoiceRowStatPropType = PropTypes.shape({
  count: PropTypes.number,
  unmappedCount: PropTypes.number,
  priceSum: PropTypes.number,
  vatPriceSum: PropTypes.number,
  unmappedPriceSum: PropTypes.number,
  unmappedVatPriceSum: PropTypes.number
})

export const workBudgetStatPropType = PropTypes.shape({
  count: PropTypes.number
})

export const invoiceBudgetStatPropType = PropTypes.shape({
  count: PropTypes.number,
  discountPriceSum: PropTypes.number,
  discountVatPriceSum: PropTypes.number,
  openBalance: PropTypes.number,
  priceSum: PropTypes.number,
  vatPriceSum: PropTypes.number
})

export const invoiceStatsPropType = PropTypes.shape({
  count: PropTypes.number.isRequired,
  openBalance: PropTypes.number.isRequired,
  priceSum: PropTypes.number.isRequired,
  vatPriceSum: PropTypes.number.isRequired,
  discountPriceSum: PropTypes.number.isRequired,
  discountVatPriceSum: PropTypes.number.isRequired
})

export const productStatPropTypes = PropTypes.shape({
  rowCount: PropTypes.number,
  productPriceSum: PropTypes.number,
  productVatPriceSum: PropTypes.number,
  productPurchasePriceSum: PropTypes.number
})

export const productGroupPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  productLineId: PropTypes.number.isRequired
})

export const workRecordStatPropType = PropTypes.shape({
  hours: PropTypes.number.isRequired,
  extraHours: PropTypes.number.isRequired
})

export const projectExtraExpenseStatPropType = PropTypes.shape({
  expense: PropTypes.number,
  hour: PropTypes.number,
  income: PropTypes.number,
  salary: PropTypes.number
})

export const projectBudgetPropType = PropTypes.shape({
  workStats: workBudgetStatPropType,
  invoiceStats: invoiceBudgetStatPropType,
  inboundInvoiceRowStats: inboundInvoiceRowStatPropType,
  productStats: productStatPropTypes,
  projectExtraExpenseStats: projectExtraExpenseStatPropType
})

export const projectEstimatedSumsPropType = PropTypes.shape({
  [projectExtraExpenseTypes.INCOME]: PropTypes.number.isRequired,
  [projectExtraExpenseTypes.EXPENSE]: PropTypes.number.isRequired,
  [projectExtraExpenseTypes.HOUR]: PropTypes.number.isRequired,
  [projectExtraExpenseTypes.SALARY]: PropTypes.number.isRequired
})

export const workSummaryStatsPropType = PropTypes.shape({
  invoicedSum: PropTypes.number.isRequired,
  notInvoicedSum: PropTypes.number.isRequired,
  expensesSum: PropTypes.number.isRequired,
  grossProfitSum: PropTypes.number.isRequired,
  vatInvoicedSum: PropTypes.number.isRequired,
  vatNotInvoicedSum: PropTypes.number.isRequired,
  vatExpensesSum: PropTypes.number.isRequired
})

const offerExpenses = {
  targetGrossProfit: PropTypes.number,
  defaultTaskGrossProfit: PropTypes.number,
  defaultProductGrossProfit: PropTypes.number,
  defaultSubcontractingGrossProfit: PropTypes.number,
  defaultExpensesGrossProfit: PropTypes.number,
  dayExpensesGrossProfit: PropTypes.number,
  socialSecurityCosts: PropTypes.number,
  workCostIncrease: PropTypes.number,
  workProjectLeadCost: PropTypes.number,
  supervisorCost: PropTypes.number,
  lossHours: PropTypes.number,
  contractPricingFactor: PropTypes.number,
  productLoss: PropTypes.number,
  productProjectLeadCost: PropTypes.number,
  storageCost: PropTypes.number,
  productCostIncrease: PropTypes.number,
  isPackageDiscountUsed: PropTypes.bool,
  travelExpenseAmount: PropTypes.number,
  travelExpense: PropTypes.number,
  travelExpenseReceivers: PropTypes.number,
  travelExpenseDaysPerWeek: PropTypes.number,
  travelExpenseHoursPerDay: PropTypes.number,
  travelExpenseAmountPerHour: PropTypes.number,
  hoursPerDay: PropTypes.number,
  foodAllowance: PropTypes.number,
  toolAllowance: PropTypes.number,
  dailyAllowance: PropTypes.number
}

export const offerPropType = PropTypes.shape({
  id: PropTypes.number,
  offerNumber: PropTypes.number,
  name: PropTypes.string,
  customerId: PropTypes.number,
  targetId: PropTypes.number,
  projectId: PropTypes.number,
  contactId: PropTypes.number,
  description: PropTypes.string,
  minimumGrossProfit: PropTypes.number,
  area: PropTypes.number,
  volume: PropTypes.number,
  rooms: PropTypes.number,
  points: PropTypes.number,
  pricingType: PropTypes.number,
  vatType: PropTypes.number,
  isRestricted: PropTypes.bool,
  packetIndustryIds: PropTypes.arrayOf(PropTypes.number),
  supplierIds: PropTypes.arrayOf(PropTypes.number),
  ...offerExpenses
})

export const offerDefaultsPropType = PropTypes.shape({
  minimumGrossProfit: PropTypes.number,
  ...offerExpenses
})

export const offerPostPropType = PropTypes.shape({
  id: PropTypes.number,
  offerId: PropTypes.number,
  name: PropTypes.string,
  parentOfferPostId: PropTypes.number,
  hasSubOfferPosts: PropTypes.bool,
  offerPostPath: PropTypes.arrayOf(PropTypes.number),
  packetIndustryId: PropTypes.number,
  ...offerExpenses
})

export const styleWidthPropType = PropTypes.shape({
  columnCount: PropTypes.number.isRequired,
  pixelWidths: PropTypes.arrayOf(PropTypes.string).isRequired
})

export const wageIncomeTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  group: PropTypes.number.isRequired,
  calculationRuleType: PropTypes.number,
  accountNumber: PropTypes.number,
  palkkaFiType: PropTypes.string
})

export const formDefaultPropsPropType = PropTypes.shape({
  model: PropTypes.shape({}),
  validationErrors: PropTypes.shape({}),
  error: PropTypes.shape({}),
  onChange: PropTypes.func,
  setWithCustomizer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  disableAll: PropTypes.bool,
  isDirty: PropTypes.bool,
  onReset: PropTypes.func,
  idPrefix: PropTypes.string
})

export const salaryGroupPropType = PropTypes.shape({
  name: PropTypes.string.isRequired
})

export const accountingAccountsPropType = PropTypes.arrayOf(PropTypes.shape({
  accountNumber: PropTypes.number,
  value: PropTypes.number,
  role: PropTypes.number
}))

export const accountingPropType = PropTypes.shape({
  isInitial: PropTypes.bool.isRequired,
  isManual: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
  accounting: accountingAccountsPropType.isRequired,
  valueAt: PropTypes.string.isRequired
})

export const workCustomStatePropType = PropTypes.shape({
  name: PropTypes.string.isRequired
})

export const preHandoverInspectionPropType = PropTypes.shape({
  id: PropTypes.number,
  employeeId: PropTypes.number,
  workId: PropTypes.number,
  changes: PropTypes.string,
  workNumber: PropTypes.number,
  createdByName: PropTypes.string,
  customerName: PropTypes.string,
  moreInfo: PropTypes.string,
  projectName: PropTypes.string,
  projectNumber: PropTypes.number,
  startedAt: PropTypes.string,
  supervisorName: PropTypes.string,
  targetName: PropTypes.string,
  workPhase: PropTypes.string
})

export const preHandoverInspectionRowsPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  startedAt: PropTypes.string,
  workerName: PropTypes.string,
  moreInfo: PropTypes.string,
  isApproved: PropTypes.bool
})

export const packetMatrixPropType = PropTypes.shape({
  packetMatrixNumber: PropTypes.string,
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string,
  isObsolete: PropTypes.bool.isRequired,
  installations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    isHidden: PropTypes.bool.isRequired
  })).isRequired,
  packetIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
})

export const tabPropType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  text: PropTypes.node.isRequired,
  contentTitle: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onSwitch: PropTypes.func,
  isDisabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isHidden: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  testId: PropTypes.string,
  requireSaved: PropTypes.bool,
  featureKey: PropTypes.string,
  isActive: PropTypes.bool
})

export const projectExtraExpensePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(projectExtraExpenseTypes)),
  value: PropTypes.number.isRequired,
  date: PropTypes.string
})

export const systemCustomerAnnualInvoicingPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(systemCustomerAnnualInvoicingTypes)),
  year: PropTypes.number,
  january: PropTypes.number,
  february: PropTypes.number,
  march: PropTypes.number,
  april: PropTypes.number,
  may: PropTypes.number,
  june: PropTypes.number,
  july: PropTypes.number,
  august: PropTypes.number,
  september: PropTypes.number,
  october: PropTypes.number,
  november: PropTypes.number,
  december: PropTypes.number
})

export const fieldsPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired
  }))
])

export const interestInvoicingPropTypes = PropTypes.shape({
  _id: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  invoiceIds: PropTypes.arrayOf(PropTypes.number).isRequired
})

const systemMessageRelationProps = {
  workId: PropTypes.number,
  supplyOfferId: PropTypes.number
}

export const systemMessageRelationPropType = PropTypes.shape(systemMessageRelationProps)

export const systemMessagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  createdBy: PropTypes.number.isRequired,
  employeeId: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  destination: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      receivers: PropTypes.arrayOf(PropTypes.string),
      cc: PropTypes.arrayOf(PropTypes.string),
      bcc: PropTypes.arrayOf(PropTypes.string)
    })
  ]),
  sendType: PropTypes.string.isRequired,
  sendAt: PropTypes.string,
  sendedAt: PropTypes.string,
  status: PropTypes.string,
  statusMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string
    })
  ])
})

export const workSystemMessagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  workId: PropTypes.number.isRequired,
  systemMessageId: PropTypes.number.isRequired
})

export const supplyOfferSystemMessagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  supplyOfferId: PropTypes.number.isRequired,
  systemMessageId: PropTypes.number.isRequired
})

export const systemCustomerSmsSettingPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  })).isRequired
})

export const supplyOfferPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  targetId: PropTypes.number,
  customerId: PropTypes.number,
  projectId: PropTypes.number,
  supplyOfferNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  supervisorId: PropTypes.number,
  isVatPrimary: PropTypes.bool,
  pricingRuleSetId: PropTypes.number,
  supplyOfferTypeId: PropTypes.number,
  supplyOfferValidUntil: PropTypes.string,
  paymentTerm: PropTypes.string,
  yourReference: PropTypes.string,
  supplyOfferDate: PropTypes.string,
  workId: PropTypes.number
})

export const supplyOfferTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  abbreviation: PropTypes.string.isRequired
})

export const supplyOfferCustomStatePropType = PropTypes.shape({
  name: PropTypes.string.isRequired
})

const ticketRelationProps = {
  workId: PropTypes.number,
  invoiceId: PropTypes.number,
  inboundInvoiceId: PropTypes.number,
  targetId: PropTypes.number,
  customerId: PropTypes.number,
  receiptId: PropTypes.number,
  receiverId: PropTypes.number,
  projectId: PropTypes.number,
  contactId: PropTypes.number
}

export const ticketPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  createdBy: PropTypes.number,
  description: PropTypes.string.isRequired,
  ticketNumber: PropTypes.number.isRequired,
  details: PropTypes.string,
  ticketTypeId: PropTypes.number,
  ticketStateId: PropTypes.number,
  employeeLevelId: PropTypes.number,
  employeeRoleIds: PropTypes.arrayOf(PropTypes.number),
  isPrivate: PropTypes.bool.isRequired,
  ...ticketRelationProps
})

export const ticketRelationPropType = PropTypes.shape(ticketRelationProps)

export const memoBasePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  memoText: PropTypes.string.isRequired,
  createdBy: PropTypes.number,
  memoType: PropTypes.number.isRequired,
  createdAt: PropTypes.string
})

export const warehousePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parentWarehouseId: PropTypes.number,
  hasSubWarehouses: PropTypes.bool.isRequired,
  isWasteWarehouse: PropTypes.bool.isRequired,
  warehousePath: PropTypes.arrayOf(PropTypes.number).isRequired
})

export const workQuickFilterPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  workTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  workCustomStateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  supervisorId: PropTypes.number,
  employeeId: PropTypes.number,
  showWorkWithNoEmployees: PropTypes.bool,
  employeeRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  showOnlyWork: PropTypes.bool,
  showOnlyProject: PropTypes.bool,
  isPrivate: PropTypes.bool,
  createdBy: PropTypes.number,
  ownOnly: PropTypes.bool
})

export const machinePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  machineNumber: PropTypes.string,
  isUnusable: PropTypes.bool,
  employeeId: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  manufacturedAt: PropTypes.string,
  purchasedAt: PropTypes.string,
  inspectionDate: PropTypes.string,
  calibrationDate: PropTypes.string,
  defaultVatClassId: PropTypes.number
})

export const workMachinePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  workId: PropTypes.number,
  machineId: PropTypes.number.isRequired,
  employeeId: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string
})

export const machineRecordPropType = PropTypes.shape({
  id: PropTypes.number,
  employeeId: PropTypes.number,
  machineId: PropTypes.number,
  workRowId: PropTypes.number,
  workId: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string,
  startDateTime: PropTypes.string,
  endDate: PropTypes.string,
  endTime: PropTypes.string,
  endDateTime: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,
  billingAmount: PropTypes.number,
  isExplicitBillingAmount: PropTypes.bool,
  isDisapproved: PropTypes.bool,
  isInUse: PropTypes.bool
})

export const machinePricingRulePropType = PropTypes.shape({
  id: PropTypes.number,
  machineId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  netPrice: PropTypes.number,
  unitPrice: PropTypes.number.isRequired,
  unit: PropTypes.string,
  rounding: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(machinePropType)),
  isFixedPrice: PropTypes.bool.isRequired,
  workRecordProductId: PropTypes.number,
  workRecordProductValueAmount: PropTypes.number,
  workRecordProductValueType: PropTypes.string,
  preventWorkRecordInvoicing: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string
})

export const supplyOfferDefaultsPropType = PropTypes.shape({
  headerText: PropTypes.string,
  endText: PropTypes.string,
  sellerSignatureText: PropTypes.string,
  footerText: PropTypes.string
})

export const timeRecordTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  systemType: PropTypes.number,
  name: PropTypes.string.isRequired,
  isWorkTime: PropTypes.bool.isRequired,
  color: PropTypes.string,
  isOutline: PropTypes.bool.isRequired,
  icon: PropTypes.string
})

export const timeRecordPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  employeeId: PropTypes.number.isRequired,
  timeRecordTypeId: PropTypes.number.isRequired,
  startedAt: PropTypes.string.isRequired,
  endedAt: PropTypes.string
})

export const genericReportGenerationPropType = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  includeReportAttachmentsTitles: PropTypes.shape({
    title: PropTypes.string,
    capitalizedTitle: PropTypes.string
  })
}

export const denominationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  denominationNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

export const denominationGroupPropType = PropTypes.shape({
  id: PropTypes.number,
  number: PropTypes.string,
  name: PropTypes.string,
  parentDenominationId: PropTypes.number,
  denominationPacketId: PropTypes.number
})

export const denominationTypePropType = PropTypes.oneOf(Object.values(denominationTypes))

export const costCentrePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  costCentreNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

const commonFormTypePropType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  formTypeLogo: PropTypes.string,
  textBeforeRows: PropTypes.string,
  textAfterRows: PropTypes.string,
  onlySubTotals: PropTypes.bool,
  withVat: PropTypes.bool,
  withoutAmountAndUnitPrices: PropTypes.bool,
  withoutDiscount: PropTypes.bool,
  withoutNameExtension: PropTypes.bool,
  withoutPrices: PropTypes.bool,
  withoutProductNumbers: PropTypes.bool,
  withoutProductRows: PropTypes.bool,
  withoutRowSum: PropTypes.bool,
  withoutTarget: PropTypes.bool,
  withoutTotalSum: PropTypes.bool,
  withoutUnitPrices: PropTypes.bool,
  withoutVat: PropTypes.bool,
  withoutVatRate: PropTypes.bool,
  withoutWorkDescription: PropTypes.bool,
  footerLeft: PropTypes.string,
  footerCenter: PropTypes.string,
  footerRight: PropTypes.string,
  useDefaultFooter: PropTypes.bool,
  type: PropTypes.string,
  isDefault: PropTypes.bool
}

export const workFormTypePropType = PropTypes.shape({
  ...commonFormTypePropType,
  showWorkNumberBarcode: PropTypes.bool,
  withoutReferenceBox: PropTypes.bool
})

export const invoiceFormTypePropType = PropTypes.shape({
  ...commonFormTypePropType,
  hideInvoiceBarcode: PropTypes.bool,
  bankAccountId1: PropTypes.number,
  bankAccountId2: PropTypes.number,
  bankAccountId3: PropTypes.number
})

export const dataTableHeaderPropType = PropTypes.shape({
  id: PropTypes.any,
  getContext: PropTypes.func.isRequired,
  getResizeHandler: PropTypes.func,
  getSize: PropTypes.func.isRequired,
  isPlaceholder: PropTypes.bool,
  column: PropTypes.shape({
    columnDef: PropTypes.shape({
      meta: PropTypes.shape({
        onValueChange: PropTypes.func,
        isHidden: PropTypes.bool
      }),
      header: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func
      ])
    }),
    getCanGlobalFilter: PropTypes.func.isRequired,
    getCanSort: PropTypes.func.isRequired,
    getCanResize: PropTypes.func.isRequired,
    getToggleSortingHandler: PropTypes.func.isRequired,
    getIsResizing: PropTypes.func,
    getIsSorted: PropTypes.func
  })
})

export const dataTableGlobalFilterPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    filter: PropTypes.string.isRequired
  })
])

const defaultInboundMessageProperties = {
  id: PropTypes.number.isRequired,
  senderName: PropTypes.string,
  createdAt: PropTypes.string,
  dropdown1: PropTypes.string
}

export const inboundMessagePropType = PropTypes.shape(defaultInboundMessageProperties)

export const formInputPropTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.node,
  inputProps: PropTypes.object,
  inputOnly: PropTypes.bool,
  required: PropTypes.bool,
  append: PropTypes.node,
  prepend: PropTypes.node,
  placeholder: PropTypes.node,
  disabled: PropTypes.bool,
  tooltipError: PropTypes.bool,
  formGroupClassName: PropTypes.string,
  onValueChange: PropTypes.func,
  helpText: PropTypes.node,
  positionFixed: PropTypes.bool,
  invalid: PropTypes.bool
}

export const chooserTemplatePropType = {
  item: PropTypes.object,
  updateField: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string
}

export const multiChooserPropType = {
  defaultProps: formDefaultPropsPropType,
  updateField: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
