import constant from 'lodash/constant'

import { deliveryTermActions } from '../../slices/deliveryTermSlice'
import { BaseIdModel } from '../types/baseModelTypes'
import { createCRUDApi } from './createCRUDApi'

export type DeliveryTermModel = {
  isSystem: boolean
  name: string
  code: string
  isDefault: boolean
  createdAt: string | null
  updatedAt: string | null
} & BaseIdModel

const { api, listenerMiddleware, useMutationsHook } = createCRUDApi<DeliveryTermModel, void, 'deliveryTerms'>({
  path: 'deliveryTerms',
  actions: deliveryTermActions,
  queryDefinition: { query: constant('') },
  titles: {
    singular: 'toimitusehto',
    plural: 'toimitusehdot',
    genetive: 'toimitusehdon',
    pluralGenetive: 'toimitusehtojen'
  }
})

export const deliveryTermApi = api
export const { middleware: deliveryTermMiddleware } = listenerMiddleware
export const {
  useGetRecordsQuery: useGetDeliveryTermsQuery
} = deliveryTermApi
export const useDeliveryTermMutations = useMutationsHook
