import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import costCentreActions from '../../actions/costCentreActions'
import denominationActions from '../../actions/denominationActions'
import inboundInvoiceActions from '../../actions/inboundInvoiceActions'
import workActions from '../../actions/workActions'
import { addSuccessNotification } from '../../helpers/notificationHelpers'
import { getBaseQuery } from './apiHelpers'
import { ApiResponse } from './types/api'

export type ProjectModel = {
  id: number
  name: string
  estimatedSales: number
  estimatedCost: number
  estimatedHours: number
  estimatedSalaryPrices: number
  estimatedSubcontracting: number
  startDate: unknown
  endDate: unknown
  projectNumber: number
  parentProjectId: number
  projectPath: number[]
  hasSubItems: boolean
  instalmentId: unknown
  targetId: unknown
  customerId: number
  contactId: unknown
  supervisorId: unknown
  defaultWarehouseId: unknown
  defaultCostCentreId: unknown
  projectCostCentreIds: number[]
  projectTypeId: number
  projectType: string
  yourReference: unknown
  ourReference: unknown
  siteNumber: unknown
  mark: unknown
  customerCostCentre: unknown
  description: unknown
  socialCosts: unknown
  state: string
  isRTVEnabled: boolean
  rtvReportEmployeeRecordsToTaxAuthority: boolean
  rtvReportEmployeeRecordsToMainContractor: boolean
  rtvProjectEndsAtProjectEndDate: number
  rtvProjectEndsAtCustom: unknown
  rtvWorkNumber: unknown
  rtvReportEmployeeDetails: boolean
  rtvReportWorkDetails: boolean
  invoicingType: unknown
}

type ProjectExtraInfo = {
  turnOver: number
  paidAmount: number
  openBalance: number
  additionalWorkAmount: number
  totalSubcontractingCosts: number
  totalCosts: number
  totalDefaultCosts: number
  totalSalaryCosts: number
  grossMargin: number
  grossMarginPercentage: number
}

export type ProjectModelWithExtraInfo = ProjectModel & ProjectExtraInfo

type AutoAssignDenominationsBody = {
  id: number
  force?: boolean
  includeSubProjects?: boolean
}

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: getBaseQuery('projects'),
  endpoints: builder => ({
    autoAssingProjectDenominations: builder.mutation<{ records: { success: number }, _embedded: ApiResponse<unknown>['_embedded'] }, AutoAssignDenominationsBody>({
      query: body => ({
        url: `/${body.id}/auto_denominations`,
        method: 'POST',
        body
      })
    })
  })
})

const listenerMiddlewareAutoAssignProjectDenominations = createListenerMiddleware()

listenerMiddlewareAutoAssignProjectDenominations.startListening({
  matcher: projectApi.endpoints.autoAssingProjectDenominations.matchFulfilled,
  effect: async(action, listenerApi) => {
    const { records, _embedded } = action?.payload || {}

    if(records) {
      // Dispatch work into base redux state
      addSuccessNotification(`Projektin litterat asetettu. Päivitetyt rivit: ${records.success}.`)
    }
    if(_embedded) {
      listenerApi.dispatch(costCentreActions.fetchSuccess(_embedded.costCentres ?? []))
      listenerApi.dispatch(denominationActions.fetchSuccess(_embedded.denominations ?? []))
      listenerApi.dispatch(workActions.workRows.fetchSuccess(_embedded.workRows ?? []))
      listenerApi.dispatch(inboundInvoiceActions.rows.fetchSuccess(_embedded.inboundInvoiceRows ?? []))
    }
  }
})

export const { useAutoAssingProjectDenominationsMutation } = projectApi
export const { middleware: autoAssignProjectDenominationsMiddleware } = listenerMiddlewareAutoAssignProjectDenominations
