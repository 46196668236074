import isEqual from 'lodash/isEqual'
import queryString from 'query-string'

import { throwIfNotInProduction } from '../../helpers/errors'
import { TODOTypeof } from '../../helpers/typeHelpers'
import { purchaseOrderActions } from '../../slices/purchaseOrderSlice'
import { BaseIdModel } from '../types/baseModelTypes'
import { createCRUDApi, createNotification } from './createCRUDApi'
import { TableOptionsModel } from './types/api'

export type PurchaseOrderModel = {
  name: string
  purchaseOrderNumber: number
  receiverId: number
  supplierId: number | null
  customerId: number | null
  projectId: number | null
  targetId: number | null
  workId: number | null
  contactId: number | null
  deliveryTypeId: number | null
  deliveryTermId: number | null
  purchaseOrderStateId: number
  deliveryDate: string
  deliveryAddress: string // Either target address, customer address or 'free text'
  deliveryCity: string
  deliveryPostalCode: string
  deliveryInstructions: string
  paymentTerm: string
  orderDate: string
  yourReference: string
  ourReference: string
  infoText: string | null // vapaa tekstikenttä
  isPartialDeliveryAllowed: boolean
  isBackOrderAllowed: boolean
} & BaseIdModel

type PatchArgs<TDataModel extends BaseIdModel> = {
  body?: Partial<TDataModel>
  id: number
  action: TODOTypeof<'set_purchase_order_state'> // use T[keyof typeof purchaseOrderActions] once common-helpers is typed and constants are marked as const
}

const serializePaginatedQueryArgs = ({ queryArgs, endpointName }: { queryArgs: TableOptionsModel, endpointName?: string }) => {
  const { ids, force, totalCount, ...newQueryArgs } = queryArgs
  return { endpointName, queryArgs: newQueryArgs }
}

const path = 'purchaseOrders'
const PURCHASE_ORDER_DEFAULT_LIMIT = 50
const PURCHASE_ORDER_TITLES = {
  singular: 'ostotilaus',
  plural: 'ostotilaukset',
  genetive: 'ostotilauksen',
  pluralGenetive: 'ostotilausten'
}
const { api: baseApi, listenerMiddleware } = createCRUDApi<PurchaseOrderModel, TableOptionsModel, 'purchaseOrders'>({
  path,
  actions: purchaseOrderActions,
  queryDefinition: {
    query: query => {
      const {
        orderBy,
        sortOrder,
        showExtraInfo,
        page,
        limit,
        q
      } = query
      // Supports only single order for now
      const order = orderBy?.[0] ?? ''
      const sort = sortOrder?.[0] ?? ''

      const searchQuery = queryString.stringify({ q, page, limit: limit ?? PURCHASE_ORDER_DEFAULT_LIMIT, order, sort, showExtraInfo })
      return `?${searchQuery}`
    },
    serializeQueryArgs: serializePaginatedQueryArgs,
    forceRefetch: ({ currentArg, previousArg }) => currentArg?.force || currentArg == null || previousArg == null ||
        !isEqual(serializePaginatedQueryArgs({ queryArgs: currentArg }), serializePaginatedQueryArgs({ queryArgs: previousArg })),
    providesTags: [{ type: 'purchaseOrders' }]
  },
  titles: PURCHASE_ORDER_TITLES
})

const api = baseApi.injectEndpoints({
  endpoints: builder => ({
    patchRecord: builder.mutation<PurchaseOrderModel, PatchArgs<PurchaseOrderModel>>({
      query: ({ body, id, action }) => ({
        url: `/${id}/${action}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
      onQueryStarted: async({ action }, { queryFulfilled }) => {
        if(action === 'set_purchase_order_state') {
          return createNotification(queryFulfilled, {
            successMessage: `Ostotilaustila päivitetty`,
            errorMessage: `Virhe ostotilaustilan päivityksessä`
          })
        }
        throwIfNotInProduction(`Unknown action ${action}`)
      }
    }),
    exportRows: builder.mutation<{ excel?: string, pdf?: string }, { excel?: boolean, pdf?: boolean, id: number }>({
      query: ({ id, ...query }) => ({
        url: `/${id}/export`,
        method: 'POST',
        body: query
      }),
      invalidatesTags: (__result, __error, { id }) => [{ type: path }, { type: path, id }],
      onQueryStarted: async({ excel, pdf }, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: `${excel && pdf ? 'Excel/pdf' : excel ? 'Excel' : 'Pdf'} luotu`,
        errorMessage: `Virhe tiedoston lataamisessa`
      })
    })
  })
})

export const purchaseOrderApi = api
export const { middleware: purchaseOrderMiddleware } = listenerMiddleware
export const {
  useGetRecordsQuery: useGetPurchaseOrdersQuery,
  useGetRecordQuery: useGetPurchaseOrderQuery,
  useCreateRecordMutation: useCreatePurchaseOrderMutation,
  useUpdateRecordMutation: useUpdatePurchaseOrderMutation,
  useDeleteRecordMutation: useDeletePurchaseOrderMutation,
  usePatchRecordMutation: usePatchPurchaseOrderMutation,
  useExportRowsMutation
} = purchaseOrderApi
