import { castToArray } from '@evelia/helpers/helpers'
import { parseTemplate } from 'url-template'

import {
  createApi,
  defaultEmbeddedNormalizer,
  defaultNormalizer,
  del,
  get,
  getSubEntityApi,
  patch,
  post,
  put,
  upload
} from '../helpers/apiHelpers'
import { parseFilterParams, parseTableOptionsFromQuery } from '../helpers/reducerHelpers'
import { contactWorkUrlTemplate } from './contactApi'
import { customerSubItemUrlTemplate } from './customerApi'
import { normalizeEmployeeResponse } from './employeeApi'
import { targetSubItemUrlTemplate } from './targetApi'
import { emailQueryTerms, optionsQueryBase, optionsQueryTerms } from './urlTemplates'

const baseUrl = '/api/work{/workId}'
const actionUrl = `${baseUrl}{/action}{?${['showExtraInfo', 'copyFiles', 'copyForms', 'createRecurring', ...optionsQueryTerms, ...emailQueryTerms].join(',')}}`
const workUrlTemplate = parseTemplate(actionUrl)
const workFileUrlTemplate = parseTemplate(`${baseUrl}/files{/workFileId}${optionsQueryBase}`)
const workEmployeeUrlTemplate = parseTemplate(`${baseUrl}/employees{/workEmployeeId}${optionsQueryBase}`)
const preHandoverInspectionUrlTemplate = parseTemplate(`${baseUrl}/pre_handover_inspections{/preHandoverInspectionId}{/action}{/actionParam}`)
const preHandoverInspectionRowUrlTemplate = parseTemplate(`${baseUrl}/pre_handover_inspections{/preHandoverInspectionId}/rows{/preHandoverInspectionRowId}{/action}`)
const workInvoicingRuleUrlTemplate = parseTemplate(`${baseUrl}/work_invoicing_rules{/workInvoicingRuleId}${optionsQueryBase}`)
const formTemplateUrlTemplate = parseTemplate(`/api/forms/work{/workId}`)
const workTicketUrlTemplate = parseTemplate(`${baseUrl}/tickets`)
const workTicketsFromTicketTemplateListUrlTemplate = parseTemplate(`${baseUrl}/ticket_template_lists{/ticketTemplateListId}`)

export const workSubItemUrlTemplate = parseTemplate(`${baseUrl}{/subItem,subItemType,subItemId}${optionsQueryBase}`)

const getFetchUrl = params => {
  if(params.customerId && params.includeWorkRows) {
    return customerSubItemUrlTemplate.expand({ ...params, subItem: 'work_with_rows' })
  } else if(params.customerId) {
    return customerSubItemUrlTemplate.expand({ ...params, subItem: 'work' })
  } else if(params.targetId) {
    return targetSubItemUrlTemplate.expand({ ...params, subItem: 'work' })
  } else if(params.contactId) {
    return contactWorkUrlTemplate.expand({ ...params, subItem: 'work' })
  } else {
    const filterParams = parseFilterParams(params)
    return workUrlTemplate.expand(filterParams)
  }
}

export const normalizeWorkForm = response => {
  const { _embedded = {}, records, record } = response
  const { workForms } = _embedded
  const data = records || record
  return {
    data,
    workForms
  }
}

export const normalizeWorkList = response => {
  const { _embedded, records, record } = response
  const {
    customers,
    targets,
    supervisors,
    contacts,
    workEmployees,
    projects,
    denominations,
    costCentres,
    workRows,
    options,
    extraInfo,
    tickets
  } = _embedded
  const data = records || record
  return {
    data,
    customers: customers || [],
    targets: targets || [],
    supervisors: normalizeEmployeeResponse(supervisors),
    contacts: contacts || [],
    workEmployees: workEmployees || [],
    projects: projects || [],
    denominations: denominations || [],
    costCentres: costCentres || [],
    workRows: workRows?.records || [],
    tableOptions: parseTableOptionsFromQuery(options),
    extraInfo: extraInfo || [],
    tickets: tickets || []
  }
}

export const normalizeWorkRowResponse = response => {
  return defaultEmbeddedNormalizer(response)
}

export const fetchWork = (params = {}) =>
  get(getFetchUrl(params))
    .then(normalizeWorkList)

export const updateWork = (work, params) => {
  const url = workUrlTemplate.expand(params)
  return put(url, {
    body: JSON.stringify(work)
  }).then(normalizeWorkList)
}
export const createWork = (work, params) =>
  post(workUrlTemplate.expand(params), {
    body: JSON.stringify(work)
  }).then(normalizeWorkList)

export const deleteWork = (work, params) =>
  del(workUrlTemplate.expand({ workId: work.id, ...params }))

export const {
  fetchApi: fetchWorkFiles,
  createApi: createWorkFile,
  updateApi: updateWorkFile,
  deleteApi: deleteWorkFile,
  normalizeResponse: normalizeWorkFileResponse
} = getSubEntityApi(workFileUrlTemplate, 'workId', 'fileId', 'workFileId', 'file', false, true)

export const doWorkPatchAction = (data, { workId, action } = {}) =>
  patch(workUrlTemplate.expand({ workId, action }), {
    body: JSON.stringify(data || {})
  }).then(normalizeWorkList)

export const doWorkPostAction = (data = {}, actionData = {}) => {
  return post(workUrlTemplate.expand(actionData), {
    body: JSON.stringify(data || {})
  }).then(normalizeWorkList)
}

export const {
  fetchApi: fetchWorkEmployees,
  createApi: createWorkEmployee,
  updateApi: updateWorkEmployee,
  deleteApi: deleteWorkEmployee,
  normalizeResponse: normalizeWorkEmployeeResponse
} = getSubEntityApi(workEmployeeUrlTemplate, 'workId', 'employeeId', 'workEmployeeId', 'employees', true, true)

export const fetchWorkSummaryStats = ({ workId }) =>
  get(workSubItemUrlTemplate.expand({ workId, subItem: 'work_stats' }))

export const fetchPreHandoverInspections = (workId, params = {}) =>
  get(preHandoverInspectionUrlTemplate.expand({ workId, ...params }))

export const updatePreHandoverInspection = (preHandoverInspection, params) =>
  put(preHandoverInspectionUrlTemplate.expand({ workId: preHandoverInspection.workId, preHandoverInspectionId: preHandoverInspection.id, ...params }), {
    body: JSON.stringify(preHandoverInspection)
  })

export const createPreHandoverInspection = preHandoverInspection =>
  post(preHandoverInspectionUrlTemplate.expand({ workId: preHandoverInspection.workId }), {
    body: JSON.stringify(preHandoverInspection)
  })

export const deletePreHandoverInspection = preHandoverInspection =>
  del(preHandoverInspectionUrlTemplate.expand({ workId: preHandoverInspection.workId, preHandoverInspectionId: preHandoverInspection.preHandoverInspectionId }))

const parsePreHandoverInspectionRowParams = params => ({
  id: params.workId,
  subItem: 'pre_handover_inspections',
  subItemId: params.preHandoverInspectionId,
  subSubItem: 'rows',
  subSubItemId: params.id,
  action: params.action
})
export const {
  fetch: fetchPreHandoverInspectionRows,
  create: createPreHandoverInspectionRow,
  update: updatePreHandoverInspectionRow,
  remove: deletePreHandoverInspectionRow
} = createApi({ base: 'work', editGetParams: parsePreHandoverInspectionRowParams, editMutateParams: parsePreHandoverInspectionRowParams, extraUrlTemplate: '{/subSubItem}{/subSubItemId}{/action}' })

export const doPreHandoverInspectionRowsPutAction = (data, params) =>
  put(preHandoverInspectionRowUrlTemplate.expand({ workId: params.workId, preHandoverInspectionId: params.preHandoverInspectionId, action: params.approve }), {
    body: JSON.stringify(data)
  })

export const createPreHandoverInspectionPdf = (data, params) =>
  post(preHandoverInspectionUrlTemplate.expand({ workId: params.workId, preHandoverInspectionId: params.preHandoverInspectionId, action: 'pdf' }), {
    body: JSON.stringify(data)
  })

export const importInstalmentRowsFromFile = (workId, file) => {
  const formData = new FormData()

  castToArray(file).forEach(file => formData.append('instalmentRows', file))
  const uploadUrl = workSubItemUrlTemplate.expand({ workId, subItem: 'instalments', subItemType: 'import' })
  return upload(uploadUrl, {
    body: formData
  }).then(normalizeWorkRowResponse)
}

export const fetchWorkInvoicingRules = ({ workId, ...params } = {}) =>
  get(workInvoicingRuleUrlTemplate.expand({ workId, ...params }))
    .then(defaultNormalizer)

export const updateWorkInvoicingRule = (workInvoicingRule, params) =>
  put(workInvoicingRuleUrlTemplate.expand({ workId: workInvoicingRule.workId, workInvoicingRuleId: workInvoicingRule.id, ...params }), {
    body: JSON.stringify(workInvoicingRule)
  })
export const doWorkAction = (record = {}, actionData = {}) => {
  return post(workSubItemUrlTemplate.expand(actionData), {
    body: JSON.stringify(record || {})
  }).then(defaultEmbeddedNormalizer)
}

export const fetchWorkFormTemplates = ({ workId, formTemplateId, formTemplateWorkFormId }) => {
  return get(formTemplateUrlTemplate.expand({ workId, formTemplateId, formTemplateWorkFormId }))
    .then(normalizeWorkForm)
}

export const saveWorkFormTemplates = ({ record, workId, formTemplateId }) => {
  return post(formTemplateUrlTemplate.expand({ workId, formTemplateId }), {
    body: JSON.stringify(record || {})
  })
    .then(defaultNormalizer)
}

export const updateWorkFormTemplates = ({ record, workId, formTemplateId, formTemplateWorkFormId }) => {
  return put(formTemplateUrlTemplate.expand({ workId, formTemplateId, formTemplateWorkFormId }), {
    body: JSON.stringify(record || {})
  })
    .then(defaultNormalizer)
}

export const createWorkTicket = (record = {}, actionData = {}) => post(workTicketUrlTemplate.expand(actionData), {
  body: JSON.stringify(record)
}).then(defaultEmbeddedNormalizer)

export const createWorkTicketsFromTicketTemplateList = (ticketTemplateListId, workId) => post(workTicketsFromTicketTemplateListUrlTemplate.expand({ ticketTemplateListId, workId })).then(defaultEmbeddedNormalizer)
