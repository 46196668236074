import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import { TODOTypeof, WithNulls } from '../../helpers/typeHelpers'
import { purchaseOrderRowActions } from '../../slices/purchaseOrderRowSlice'
import { BaseIdModel } from '../types/baseModelTypes'
import { getBaseQuery } from './apiHelpers'
import { createNotification } from './createCRUDApi'
import { ApiRecordResponse, ApiResponse } from './types/api'

export type PurchaseOrderRowModel = {
  id: number
  name: string | null
  purchaseOrderId: number
  supplierId: number | null
  productId: number | null
  createdBy: number | null
  count: number | null
  price: number | null
  discount: number | null
  comment: string | null
  unit: string | null
  nameExtension: string | null
  productNumber: string | null
  confirmedAt: string | null
  confirmedPrice: number | null
  partialDelivery: boolean
  productLineCode: string | null
  isListPriceCheaper: boolean
  type: TODOTypeof<'product' | 'comment'> // use T[keyof typeof purchaseOrderRowTypes] once common-helpers is typed and constants are marked as const
} & BaseIdModel

type CreateManyPurchaseOrderRowModel = Omit<WithNulls<PurchaseOrderRowModel>, 'id'>

export const purchaseOrderRowApi = createApi({
  reducerPath: 'purchaseOrderRowApi',
  baseQuery: getBaseQuery('purchase_orders'),
  endpoints: builder => ({
    getPurchaseOrderRows: builder.query<ApiResponse<PurchaseOrderRowModel>, BaseIdModel>({
      query: ({ id: purchaseOrderId }) => `/${purchaseOrderId}/purchase_order_rows`
    }),
    createPurchaseOrderRow: builder.mutation<ApiRecordResponse<PurchaseOrderRowModel>, Omit<PurchaseOrderRowModel, 'id'>>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows`,
        method: 'POST',
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivi luotu',
        errorMessage: 'Virhe ostotilausrivin luonnissa'
      })
    }),
    createManyPurchaseOrderRows: builder.mutation<ApiResponse<PurchaseOrderRowModel>, { purchaseOrderId: number, purchaseOrderRows: CreateManyPurchaseOrderRowModel[] }>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows/bulk`,
        method: 'POST',
        body: body.purchaseOrderRows
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivit luotu',
        errorMessage: 'Virhe ostotilausrivien luonnissa'
      })
    }),
    updatePurchaseOrderRow: builder.mutation<ApiRecordResponse<PurchaseOrderRowModel>, PurchaseOrderRowModel>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows/${body.id}`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivi päivitetty',
        errorMessage: 'Virhe ostotilausrivin päivityksessä'
      })
    }),
    deletePurchaseOrderRow: builder.mutation<BaseIdModel, PurchaseOrderRowModel>({
      query: body => ({
        url: `/${body.purchaseOrderId}/purchase_order_rows/${body.id}`,
        method: 'DELETE'
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivi poistettu',
        errorMessage: 'Virhe ostotilausrivin poistossa'
      })
    }),
    updatePurchaseOrderRowPrices: builder.mutation<ApiResponse<PurchaseOrderRowModel>, { purchaseOrderId: number, supplierId: number }>({
      query: ({ purchaseOrderId, supplierId }) => ({
        url: `/${purchaseOrderId}/purchase_order_rows/update_prices`,
        method: 'PATCH',
        body: { supplierId }
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilausrivien hinnat päivitetty',
        errorMessage: 'Virhe ostotilausrivien hintojen päivityksessä'
      })
    })
  })
})

const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: purchaseOrderRowApi.endpoints.getPurchaseOrderRows.matchFulfilled,
  effect: (action, listenerApi) => {
    const { records } = action?.payload ?? {}
    if(records) {
      listenerApi.dispatch(purchaseOrderRowActions.fetchSuccess(records))
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderRowApi.endpoints.createPurchaseOrderRow.matchFulfilled,
  effect: (action, listenerApi) => {
    const { record } = action?.payload ?? {}
    if(record) {
      listenerApi.dispatch(purchaseOrderRowActions.createSuccess(record))
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderRowApi.endpoints.createManyPurchaseOrderRows.matchFulfilled,
  effect: (action, listenerApi) => {
    const { records } = action?.payload ?? {}
    if(records) {
      listenerApi.dispatch(purchaseOrderRowActions.createSuccess(records))
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderRowApi.endpoints.updatePurchaseOrderRow.matchFulfilled,
  effect: (action, listenerApi) => {
    const { record } = action?.payload ?? {}
    if(record) {
      listenerApi.dispatch(purchaseOrderRowActions.updateSuccess(record))
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderRowApi.endpoints.deletePurchaseOrderRow.matchFulfilled,
  effect: (action, listenerApi) => {
    if(action?.payload?.id) {
      listenerApi.dispatch(purchaseOrderRowActions.deleteSuccess(action.payload))
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderRowApi.endpoints.updatePurchaseOrderRowPrices.matchFulfilled,
  effect: (action, listenerApi) => {
    const { records } = action?.payload ?? {}
    if(records) {
      listenerApi.dispatch(purchaseOrderRowActions.fetchSuccess(records))
    }
  }
})

export const { middleware: purchaseOrderRowMiddleware } = listenerMiddleware
export const {
  useGetPurchaseOrderRowsQuery,
  useCreateManyPurchaseOrderRowsMutation,
  useUpdatePurchaseOrderRowPricesMutation
} = purchaseOrderRowApi

export const usePurchaseOrderRowMutations = () => {
  const [save] = purchaseOrderRowApi.useCreatePurchaseOrderRowMutation()
  const [update] = purchaseOrderRowApi.useUpdatePurchaseOrderRowMutation()
  const [remove] = purchaseOrderRowApi.useDeletePurchaseOrderRowMutation()
  return { save, update, remove }
}
