import CommonValueFormGroup from './CommonValueFormGroup'
import ColorFormInput from './inputs/ColorFormInput'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from './propTypes'

const ColorFormGroup = props => (
  <CommonValueFormGroup {...commonFormInputDefaultProps} {...props} Component={ColorFormInput} />
)
ColorFormGroup.propTypes = commonFormInputPropTypes

export default ColorFormGroup
