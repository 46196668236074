import constant from 'lodash/constant'

import { purchaseOrderStateActions } from '../../slices/purchaseOrderStateSlice'
import { BaseIdModel } from '../types/baseModelTypes'
import { createCRUDApi } from './createCRUDApi'

export type PurchaseOrderStateModel = {
  isSystem: boolean
  name: string
  color: string
  createdAt: string | null
  updatedAt: string | null
} & BaseIdModel

const { api, listenerMiddleware, useMutationsHook } = createCRUDApi<PurchaseOrderStateModel, void, 'purchaseOrderStates'>({
  path: 'purchaseOrderStates',
  actions: purchaseOrderStateActions,
  queryDefinition: { query: constant('') },
  titles: {
    singular: 'ostotilaustila',
    plural: 'ostotilaustilat',
    genetive: 'ostotilaustilan',
    pluralGenetive: 'ostotilaustilojen'
  }
})

export const purchaseOrderStateApi = api
export const { middleware: purchaseOrderStateMiddleware } = listenerMiddleware
export const {
  useGetRecordsQuery: useGetPurchaseOrderStatesQuery
} = purchaseOrderStateApi

export const usePurchaseOrderStateMutations = useMutationsHook
