import moment, { dateFormats } from '@evelia/helpers/dateHelpers'

export const stringToMoment = value => {
  const maybeMoment = moment(value, [moment.ISO_8601, dateFormats.isoTimeFormat])
  return maybeMoment.isValid()
    ? maybeMoment
    : value
}

export const parseForForm = value => {
  const maybeMoment = moment(value, [moment.ISO_8601, dateFormats.isoTimeFormat])
  return maybeMoment.isValid()
    ? maybeMoment.format()
    : value
}
