import queryString from 'query-string'

import { deliveryTypeActions } from '../../slices/deliveryTypeSlice'
import { BaseIdModel } from '../types/baseModelTypes'
import { createCRUDApi } from './createCRUDApi'

export type DeliveryTypeModel = {
  isSystem: boolean
  name: string
  code: string
  supplierId: number | null
  type: string
  address?: string
  city?: string
  postalCode?: string
  createdAt: string | null
  updatedAt: string | null
} & BaseIdModel

type DeliveryTypeQuery = {
  supplierId?: number
}

const { api, listenerMiddleware, useMutationsHook } = createCRUDApi<DeliveryTypeModel, DeliveryTypeQuery, 'deliveryTypes'>({
  path: 'deliveryTypes',
  actions: deliveryTypeActions,
  queryDefinition: {
    query: ({ supplierId } = {}) => `?${queryString.stringify({ supplierId })}`
  },
  titles: {
    singular: 'toimitustapa',
    plural: 'toimitustavat',
    genetive: 'toimitustavan',
    pluralGenetive: 'toimitustapojen'
  }
})

export const deliveryTypeApi = api
export const { middleware: deliveryTypeMiddleware } = listenerMiddleware
export const useDeliveryTypeMutations = useMutationsHook
export const {
  useGetRecordsQuery: useGetDeliveryTypesQuery
} = deliveryTypeApi
