import { createEntityAdapter } from '@reduxjs/toolkit'

import { PurchaseOrderRowModel } from '../api/rtk/purchaseOrderRowApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const purchaseOrderRowAdapter = createEntityAdapter<PurchaseOrderRowModel, number>({ selectId: row => row.id })
const purchaseOrderRowSlice = createGenericSlice({
  name: 'purchaseOrderRows',
  entityAdapter: purchaseOrderRowAdapter
})

export const purchaseOrderRowEntitySelectors = purchaseOrderRowAdapter.getSelectors<EveliaRootState>(state => state.purchaseOrders.purchaseOrderRows)
export const purchaseOrderRowActions = purchaseOrderRowSlice.actions

export default purchaseOrderRowSlice
