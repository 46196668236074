import { isAfter, isSameOrAfter } from '@evelia/helpers/dateHelpers'
import { getSelf, sortById, sortByProperty } from '@evelia/helpers/helpers'
import { createCachedSelector } from 're-reselect'

import { denominationTypes, projectExtraExpenseTypes, workSystemTypes } from '../constants'
import { rowTypes } from '../containers/ProjectPage/denominationBudgetComponents/denominationBudgetTableConstants'
import { projectBudgetFieldMap } from '../containers/ProjectPage/ProjectBudgetInput'
import {
  getFilterItemsByFieldSelector,
  getFilterItemsByFieldWithValueArraySelector,
  getFindItemByIdSelector,
  getFindItemsByIdsSelector,
  getMemoSelector,
  getSubentitySelectors
} from '../helpers/selectorHelpers'
import { findDenominationGroupsByType, findDenominationsByDenominationGroupId, getDenominationGroupsFromArgument } from './denominationSelectors'
import { getWorkFromArguments } from './workSelectors'

export const getProjectsFromArgument = arg => arg.projects ? arg.projects.records : arg
const getProjectBudgetsFromArgument = arg => arg.projects ? arg.projects.budgets.records : arg
const getProjectAdditionalPersonsFromArgument = arg => arg.projects ? arg.projects.additionalPersons.records : arg
const getProjectEmployeesFromArgument = arg => arg.projects ? arg.projects.employees.records : arg
const getProjectContactsFromArgument = arg => arg.projects ? arg.projects.contacts.records : arg
const getProjectExtraExpensesFromArgument = arg => arg.projects ? arg.projects.projectExtraExpenses.records : arg
const getProjectExtraBudgetsFromArgument = arg => arg.projects ? arg.projects.projectExtraBudgets.records : arg
const getProjectDenominationBudgetsFromArgument = arg => arg.projects ? arg.projects.projectDenominationBudgets.records : arg

export const findProjectWithId = getFindItemByIdSelector(getProjectsFromArgument)
export const findProjectsWithIdList = getFindItemsByIdsSelector(getProjectsFromArgument)

export const {
  getSubItemsOfItem: getFilesOfProject,
  rejectSubItemsOfItem: rejectFilesOfProject
} = getSubentitySelectors('projects', 'files', 'files', 'projectId', 'fileId')

export const findMemosByProjectId = getMemoSelector('projects', 'projectId')
const findProjectSubProjectsRecursive = (items, projectId, extraInfo, showExtraInfo = true) => {
  const subProjects = items.filter(item => {
    return item.projectPath?.[item.projectPath.length - 2] === projectId
  })
  const subProjectsWithExtraInfo = showExtraInfo ? subProjects.map(subProject => ({ ...subProject, ...extraInfo.find(info => info.projectId === subProject.id) })) : subProjects
  return subProjectsWithExtraInfo.map(subProject => subProject.hasSubItems ? { ...subProject, subProjects: findProjectSubProjectsRecursive(items, subProject.id, extraInfo, showExtraInfo) } : subProject)
}

const findProjectAndSubProjectIdsRecursive = (items, projectId) => {
  const subProjects = items.filter(item => {
    return item.projectPath?.[item.projectPath.length - 2] === projectId
  })
  return [projectId, ...subProjects.flatMap(project => findProjectAndSubProjectIdsRecursive(items, project.id))]
}

export const getProjectsByTableIdsSelector = isDataTable => createCachedSelector(
  state => ({ records: state.projects.records, extraInfo: state.projects.extraInfo }),
  (state, tableIdentifier) => state.projects.tableOptions[tableIdentifier || 'default'],
  ({ records, extraInfo }, tableOptions) => {
    if(!tableOptions.ids.length && tableOptions.q == null && !Object.keys(tableOptions.filters).length) {
      return records
    }

    const projectMap = new Map()
    tableOptions.ids.forEach((id, index) => {
      const idProject = findProjectWithId(records, id)
      if(idProject) {
        const subProjects = !isDataTable ? findProjectSubProjectsRecursive(records, idProject.id, extraInfo) : []
        const projectExtraInfo = !isDataTable ? extraInfo.find(info => info.projectId === id) : undefined
        projectMap.set(idProject.id, { ...idProject, ...projectExtraInfo, subProjects })
        if(tableOptions.q == null && isDataTable) {
          records.filter(project => project.projectPath.toString().startsWith(idProject.projectPath[0].toString() + ',')).forEach(project => projectMap.set(project.id, project))
        }
      }
    })

    return [...projectMap.values()]
  }
)((state, tableIdentifier) => tableIdentifier)

export const findWorkByProjectId = createCachedSelector(
  getWorkFromArguments,
  (state, projectId) => Number(projectId),
  (workList, projectId) => workList.filter(work => work.projectId === projectId && work.type !== workSystemTypes.WORK_TYPE_INSTALMENT)
    .sort(sortById)
)((state, projectId) => projectId)

const {
  getSubItemsOfItem: getInvoicesOfProject
} = getSubentitySelectors('projects', 'invoices', 'invoices', 'projectId', 'invoiceId')

const {
  getSubItemsOfItem: getInboundInvoicesOfProject
} = getSubentitySelectors('projects', 'inboundInvoices', 'inboundInvoices', 'projectId', 'inboundInvoiceId')

export const findBudgetByProjectId = createCachedSelector(
  state => state.projects.budgets.records,
  (state, projectId) => Number(projectId),
  (budgets, projectId) => budgets.find(budget => budget.projectId === projectId)
)((state, projectId) => projectId)

const findDenominationBudgetsByProjectId = createCachedSelector(
  state => state.projects.budgetsByDenomination.records,
  (state, { projectId }) => Number(projectId),
  (budgets, projectId) => budgets.filter(budget => budget.projectId === projectId)
)((state, { projectId }) => projectId)

const findProjectDenominationBudgetsByProjectId = createCachedSelector(
  getProjectDenominationBudgetsFromArgument,
  (state, { projectId }) => Number(projectId),
  (projectDenominationBudgets, projectId) => projectDenominationBudgets.filter(projectDenominationBudget => projectDenominationBudget.projectId === projectId)
)((state, { projectId }) => projectId)

export const findProjectDenominationBudgetsByProjectIdDenominationIdAndType = createCachedSelector(
  findProjectDenominationBudgetsByProjectId,
  (state, { denominationId }) => Number(denominationId),
  (state, { type }) => type,
  (projectDenominationBudgets, denominationId, type) => projectDenominationBudgets.filter(projectDenominationBudget => projectDenominationBudget.denominationId === denominationId && projectDenominationBudget.type === type)
)((state, { projectId, denominationId, type }) => `${projectId}_${denominationId}_${type}`)

export const findDenominationBudgetsByProjectAndDenominationGroupId = createCachedSelector(
  state => state.projects.budgetsByDenomination.records,
  (state, { projectId }) => Number(projectId),
  (state, { denominationGroupId }) => findDenominationsByDenominationGroupId(state, denominationGroupId),
  (state, { projectId }) => findProjectDenominationBudgetsByProjectId(state, { projectId }),
  (budgets, projectId, denominations, projectDenominationBudgets) => {
    return denominations.sort(sortByProperty('number', false, Number)).map(denomination => (
      {
        ...(budgets.find(budget => budget.projectId === projectId && budget.denominationId === denomination.id) || { ...initialBudget, projectId, denominationId: denomination.id }),
        projectDenominationBudgets: projectDenominationBudgets
          .filter(projectDenominationBudget => projectDenominationBudget.denominationId === denomination.id)
          .map(projectDenominationBudget => ({ ...projectDenominationBudget, [projectBudgetFieldMap[projectDenominationBudget.type].field]: projectDenominationBudget.value }))
      }
    )
    )
  }
)((state, { projectId, denominationGroupId }) => `${projectId}_${denominationGroupId}`)

export const calculateDenominationBudgetsByDenominationGroup = createCachedSelector(
  findDenominationBudgetsByProjectId,
  (state, { onlyWorkType }) => onlyWorkType ? findDenominationGroupsByType(state, denominationTypes.DENOMINATION_TYPE_WORK) : getDenominationGroupsFromArgument(state),
  (budgets, denominationGroups) => {
    const budgetsByDenominationGroup = budgets.reduce((acc, budget) => {
      const denominationGroupId = budget.denominationGroupId
      acc[denominationGroupId] = acc[denominationGroupId] || []
      acc[denominationGroupId].push(budget)
      return acc
    }, {})
    const budgetSumsByDenominationGroup = [...denominationGroups.sort(sortByProperty('number', false, Number)), null].map(denominationGroup => {
      const id = denominationGroup?.id ?? null
      const budgets = budgetsByDenominationGroup[id]
      return {
        ...reduceBudgets(budgets || []),
        id: id || null,
        denominationGroup,
        rowType: rowTypes.ROW_TYPE_DENOMINATION_GROUP
      }
    })

    // TODO: nested groups
    return budgetSumsByDenominationGroup.map(budgetSums => {
      if(budgetSums.id == null) {
        return {
          ...budgetSums,
          byInboundInvoice: budgetsByDenominationGroup.null?.[0]?.byInboundInvoice,
          byWork: budgetsByDenominationGroup.null?.[0]?.byWork
        }
      } else {
        return {
          ...budgetSums,
          projectExtraBudgetStats: budgetSums.projectExtraBudgetStats ? Object.entries(budgetSums.projectExtraBudgetStats).reduce(reduceKeyValueSum(false), getInitialBudgetValues(budgetSums.initialBudgets)) : {}
        }
      }
    })
  }
)((state, { projectId }) => projectId)

export const getInvoiceTreeDataOfProject = createCachedSelector(
  getInvoicesOfProject,
  (state, projectId) => Number(projectId),
  (invoices, projectId) => invoices.map(invoice => ({ ...invoice, projectId }))
)((state, projectId) => projectId)

export const getInboundInvoiceTreeDataOfProject = createCachedSelector(
  getInboundInvoicesOfProject,
  (state, projectId) => Number(projectId),
  (inboundInvoices, projectId) => inboundInvoices.map(inboundInvoice => ({ ...inboundInvoice, projectId }))
)((state, projectId) => projectId)

export const findProjectAndSubProjects = createCachedSelector(
  getProjectsFromArgument,
  (state, projectId) => Number(projectId),
  (items, projectId) => items.filter(item => item.projectPath.includes(projectId))
)((state, projectId) => projectId)

const findProjectAndSubProjectBudgets = createCachedSelector(
  findProjectAndSubProjects,
  getProjectBudgetsFromArgument,
  (projects, budgets) => budgets.filter(budget => projects.find(project => project.id === budget.projectId))
)((state, rootProjectId) => rootProjectId)

export const getProjectTotalEstimations = createCachedSelector(
  findProjectAndSubProjects,
  getProjectExtraBudgetsFromArgument,
  (projects, projectExtraBudgets) => projects.reduce((acc, project) => {
    const projectBudgetSums = getProjectBudgetSums(projectExtraBudgets, { projectId: project.id, project })
    return {
      [projectExtraExpenseTypes.INCOME]: acc[projectExtraExpenseTypes.INCOME] + (projectBudgetSums[projectExtraExpenseTypes.INCOME] || 0),
      [projectExtraExpenseTypes.EXPENSE]: acc[projectExtraExpenseTypes.EXPENSE] + (projectBudgetSums[projectExtraExpenseTypes.EXPENSE] || 0),
      [projectExtraExpenseTypes.HOUR]: acc[projectExtraExpenseTypes.HOUR] + (projectBudgetSums[projectExtraExpenseTypes.HOUR] || 0),
      [projectExtraExpenseTypes.SALARY]: acc[projectExtraExpenseTypes.SALARY] + (projectBudgetSums[projectExtraExpenseTypes.SALARY] || 0),
      [projectExtraExpenseTypes.SUBCONTRACTING]: acc[projectExtraExpenseTypes.SUBCONTRACTING] + (projectBudgetSums[projectExtraExpenseTypes.SUBCONTRACTING] || 0)
    }
  }, { [projectExtraExpenseTypes.INCOME]: 0, [projectExtraExpenseTypes.EXPENSE]: 0, [projectExtraExpenseTypes.HOUR]: 0, [projectExtraExpenseTypes.SALARY]: 0, [projectExtraExpenseTypes.SUBCONTRACTING]: 0 }
  )
)((state, projectId) => `${projectId}`)

const reduceKeyValueSum = invert => (statsAcc, [key, value]) => ({
  ...statsAcc,
  [key]: value == null ? statsAcc[key] : ((statsAcc[key] ?? 0) + (value * (invert ? -1 : 1)))
})

const initialBudget = {
  workStats: {
    count: null
  },
  invoiceStats: {
    count: null,
    openBalance: null,
    priceSum: null,
    vatPriceSum: null,
    discountPriceSum: null,
    discountVatPriceSum: null
  },
  inboundInvoiceRowStats: {
    count: null,
    unmappedCount: null,
    priceSum: null,
    vatPriceSum: null,
    unmappedPriceSum: null,
    unmappedVatPriceSum: null,
    subcontractingPriceSum: null,
    unmappedSubcontractingPriceSum: null
  },
  workRecordStats: {
    hours: null,
    extraHours: null,
    salaryPrice: null,
    socialCostSalaryPrice: null
  },
  productStats: {
    rowCount: null,
    productPriceSum: null,
    productVatPriceSum: null,
    productPurchasePriceSum: null
  },
  projectExtraExpenseStats: {
    expense: null,
    hour: null,
    income: null,
    salary: null,
    subcontracting: null
  },
  projectExtraBudgetStats: {
    expense: null,
    hour: null,
    income: null,
    salary: null,
    subcontracting: null
  },
  initialBudgets: {
    estimatedSales: null,
    estimatedCost: null,
    estimatedHours: null,
    estimatedSalaryPrices: null,
    estimatedSubcontracting: null
  },
  totalInvoicePriceSum: null,
  totalExpensesSum: null,
  totalWorkRecordExpensesSum: null,
  totalBasicHours: null,
  totalExtraHours: null,
  totalSubcontractingSum: null,
  estimatedSales: null,
  estimatedCost: null,
  estimatedHours: null,
  estimatedSalaryPrices: null,
  estimatedSubcontracting: null
}

const reduceBudgets = projectBudgets => projectBudgets.reduce((acc, budget) => {
  return {
    workStats: Object.entries(budget.workStats).reduce(reduceKeyValueSum(false), acc.workStats),
    invoiceStats: Object.entries(budget.invoiceStats).reduce(reduceKeyValueSum(false), acc.invoiceStats),
    inboundInvoiceRowStats: Object.entries(budget.inboundInvoiceRowStats).reduce(reduceKeyValueSum(false), acc.inboundInvoiceRowStats),
    workRecordStats: Object.entries(budget.workRecordStats).reduce(reduceKeyValueSum(false), acc.workRecordStats),
    productStats: Object.entries(budget.productStats).reduce(reduceKeyValueSum(false), acc.productStats),
    projectExtraExpenseStats: Object.entries(budget.projectExtraExpenseStats).reduce(reduceKeyValueSum(false), acc.projectExtraExpenseStats),
    projectExtraBudgetStats: Object.entries(budget.projectExtraBudgetStats).reduce(reduceKeyValueSum(false), acc.projectExtraBudgetStats),
    initialBudgets: Object.entries(budget.initialBudgets).reduce(reduceKeyValueSum(false), acc.initialBudgets),
    totalInvoicePriceSum: acc.totalInvoicePriceSum + budget.totalInvoicePriceSum,
    totalExpensesSum: acc.totalExpensesSum + budget.totalExpensesSum,
    totalWorkRecordExpensesSum: acc.totalWorkRecordExpensesSum + budget.totalWorkRecordExpensesSum,
    totalBasicHours: acc.totalBasicHours + budget.totalBasicHours,
    totalExtraHours: acc.totalExtraHours + budget.totalExtraHours,
    totalSubcontractingSum: acc.totalSubcontractingSum + budget.totalSubcontractingSum,
    estimatedSales: acc.estimatedSales + budget.estimatedSales,
    estimatedCost: acc.estimatedCost + budget.estimatedCost,
    estimatedHours: acc.estimatedHours + budget.estimatedHours,
    estimatedSalaryPrices: acc.estimatedSalaryPrices + budget.estimatedSalaryPrices,
    estimatedSubcontracting: acc.estimatedSubcontracting + budget.estimatedSubcontracting
  }
}, initialBudget)

export const getProjectTotalBudgets = createCachedSelector(
  findProjectAndSubProjectBudgets,
  reduceBudgets
)((state, rootProjectId) => rootProjectId)

export const findAdditionalPersonsOfProject = getFilterItemsByFieldSelector(getProjectAdditionalPersonsFromArgument, 'projectId', Number)
export const findEmployeesOfProject = getFilterItemsByFieldSelector(getProjectEmployeesFromArgument, 'projectId', Number)
export const findContactsOfProject = getFilterItemsByFieldSelector(getProjectContactsFromArgument, 'projectId', Number)
export const getProjectsOfContact = getFilterItemsByFieldSelector(getProjectsFromArgument, 'contactId', Number)
export const findProjectExtraExpenses = getFilterItemsByFieldSelector(getProjectExtraExpensesFromArgument, 'projectId', Number)

export const findProjectExtraExpenseSummary = createCachedSelector(
  (state, projectId, __startDate, __endDate, excludeSubProjects) => {
    if(excludeSubProjects) {
      return getFilterItemsByFieldSelector(getProjectExtraExpensesFromArgument, 'projectId', Number)(state, projectId)
    }
    return getFilterItemsByFieldWithValueArraySelector(getProjectExtraExpensesFromArgument, 'projectId', Number)(state, findProjectAndSubProjectIdsRecursive(state.projects.records, projectId))
  },
  (__state, __projectId, startDate) => startDate,
  (__state, __projectId, __startDate, endDate) => endDate,
  (extraExpenses, startDate, endDate) => {
    const valuesByType = extraExpenses.reduce((acc, extraExpense) => {
      const value = extraExpense.value
      if(startDate && !isSameOrAfter(extraExpense.date, startDate)) {
        return acc
      }
      if(endDate && isAfter(extraExpense.date, endDate)) {
        return acc
      }
      const summaryRow = {
        id: extraExpense.id,
        type: extraExpense.type,
        value: (acc[extraExpense.type]?.value || 0) + value,
        date: null,
        description: null
      }
      return {
        ...acc,
        [extraExpense.type]: summaryRow
      }
    }, {})
    return Object.values(valuesByType)
  }
)((__state, projectId, __startDate, __endDate) => `${projectId}`)

export const getProjectExtraBudgetsByProjectId = createCachedSelector(
  (state, { projectId }) => getFilterItemsByFieldSelector(getProjectExtraBudgetsFromArgument, ['projectId'])(state, [projectId]),
  getSelf
)((state, { projectId }) => `${projectId}`)

export const getProjectExtraBudgetsByType = createCachedSelector(
  (state, { projectId, projectExtraExpenseType }) => getFilterItemsByFieldSelector(getProjectExtraBudgetsFromArgument, ['projectId', 'type'])(state, [projectId, projectExtraExpenseType]),
  getSelf
)((state, { projectId, projectExtraExpenseType }) => `${projectId}_${projectExtraExpenseType}`)

export const getProjectExtraBudgetSumByType = createCachedSelector(
  (state, { projectId, projectExtraExpenseType }) => getFilterItemsByFieldSelector(getProjectExtraBudgetsFromArgument, ['projectId', 'type'])(state, [projectId, projectExtraExpenseType]),
  projectExtraBudgets => projectExtraBudgets.reduce((acc, extraBudget) => acc + extraBudget.value, 0)
)((state, { projectId, projectExtraExpenseType }) => `${projectId}_${projectExtraExpenseType}`)

const calculateTotalBudgetValues = (budgets, initialData) => budgets.reduce((acc, budget) => {
  return {
    ...acc,
    [budget.type]: acc[budget.type] + budget.value
  }
}, getInitialBudgetValues(initialData))

export const getProjectBudgetSums = createCachedSelector(
  (state, { projectId, project }) => project || findProjectWithId(state, projectId),
  (state, { projectId }) => getFilterItemsByFieldSelector(getProjectExtraBudgetsFromArgument, ['projectId'])(state, [projectId]),
  (project, projectExtraBudgets) => calculateTotalBudgetValues(projectExtraBudgets, project)
)((state, { projectId }) => `${projectId}`)

const getInitialBudgetValues = project => ({
  [projectExtraExpenseTypes.INCOME]: project?.estimatedSales ?? 0,
  [projectExtraExpenseTypes.EXPENSE]: project?.estimatedCost ?? 0,
  [projectExtraExpenseTypes.HOUR]: project?.estimatedHours ?? 0,
  [projectExtraExpenseTypes.SALARY]: project?.estimatedSalaryPrices ?? 0,
  [projectExtraExpenseTypes.SUBCONTRACTING]: project?.estimatedSubcontracting ?? 0
})
