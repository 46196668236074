import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { denominationSystemTypes } from '@evelia/helpers/constants'
import { now } from '@evelia/helpers/dateHelpers'
import { getDiscountCoefficient, round2decimals, round8decimals } from '@evelia/helpers/helpers'

import { useLazyGetProductDenominationQuery } from '../../api/rtk/denominationApi'
import { nonPriceWorkRowTypes, workRowTypes } from '../../constants'
import { getAccountNumberByVatType } from '../../selectors/accountSelectors'
import {
  findDefaultVatRate,
  getCombinedVatRateWithClassId,
  getVatRateByVatType
} from '../../selectors/vatSelectors'
import { getDenominationSystemType } from '../../selectors/whoAmISelectors'
import { useCombinedVatRates } from '../Vat/vatCodeHooks'
import { calculateGrossProfitPercentage } from './workRowOnChangeEvents'

export const getPrimaryPriceSumField = opts => opts.isVatPrimary ? 'vatPriceSum' : 'priceSum'
export const getSecondaryPriceSumField = opts => !opts.isVatPrimary ? 'vatPriceSum' : 'priceSum'
const getPrimaryPriceField = opts => opts.isVatPrimary ? 'vatPrice' : 'price'
const getSecondaryPriceField = opts => !opts.isVatPrimary ? 'vatPrice' : 'price'

const defaultWorkRowData = {
  id: null,
  workId: null,
  vatClassId: null,
  vatCodeId: null,
  warehouseId: null,
  denominationId: null,
  deliveryDate: null,
  accountNumber: null,
  comment: null,
  grossProfit: null,
  grossProfitPercentage: null,
  name: null,
  nameExtension: null,
  normHour: null,
  normHourPrice: null,
  preventInvoicing: false,
  count: 1,
  discount: 0,
  price: 0,
  priceSum: 0,
  normHourPriceSum: 0,
  vatPrice: 0,
  vatPriceSum: 0,
  productId: null,
  productLineCode: null,
  productNumber: null,
  productTaskDescription: null,
  productTaskPacketIndustryName: null,
  purchasePrice: null,
  unit: null,
  type: workRowTypes.WORK_ROW_TYPE_PRODUCT
}
export const getInitialWorkRowData = data => ({ ...defaultWorkRowData, ...data })

const defaultWorkCommentRowData = {
  workId: null,
  comment: null,
  preventInvoicing: false,
  type: workRowTypes.WORK_ROW_TYPE_COMMENT
}
const getInitialWorkCommentRowData = data => ({ ...defaultWorkCommentRowData, ...data })

const getInitialInstalmentRowData = data => ({
  ...defaultWorkRowData,
  productNumber: 'Erä',
  estimatedInvoicingDate: null,
  type: workRowTypes.WORK_ROW_TYPE_INSTALMENT,
  ...data
})

export const useInitializeNewWorkRow = work => {
  const defaultVatRate = useSelector(state => findDefaultVatRate(state, work.vatType))
  const { id: workId, isFixedPrice, defaultDenominationId, defaultCostCentreId } = work

  return useCallback((workRowType, isTemplate) => {
    const initialData = {
      workId,
      vatClassId: defaultVatRate?.vatClassId,
      vatCodeId: defaultVatRate?.id,
      preventInvoicing: !!isFixedPrice,
      denominationId: defaultDenominationId,
      costCentreId: defaultCostCentreId,
      deliveryDate: now()
    }

    let newRowData
    switch(workRowType) {
      case workRowTypes.WORK_ROW_TYPE_COMMENT:
        newRowData = getInitialWorkCommentRowData(initialData)
        break
      case workRowTypes.WORK_ROW_TYPE_SUB_TOTAL:
        newRowData = getInitialWorkCommentRowData({ ...initialData, type: workRowTypes.WORK_ROW_TYPE_SUB_TOTAL })
        break
      case workRowTypes.WORK_ROW_TYPE_INSTALMENT:
        newRowData = getInitialInstalmentRowData({ ...initialData, type: isTemplate ? workRowTypes.WORK_ROW_TYPE_TEMPLATE : workRowTypes.WORK_ROW_TYPE_INSTALMENT })
        break
      default:
        newRowData = getInitialWorkRowData(initialData)
    }
    return newRowData
  }, [defaultCostCentreId, defaultDenominationId, defaultVatRate?.id, defaultVatRate?.vatClassId, isFixedPrice, workId])
}

export const createWorkRowFromProduct = (workRowModel, product, combinedVatRates, work, overrideAccountNumber, productDenominationId) => {
  const {
    id: workId,
    isVatPrimary,
    vatType,
    isFixedPrice,
    defaultDenominationId,
    defaultCostCentreId
  } = work
  const { warehouseId, count, denominationId } = workRowModel
  const {
    id: productId,
    productLineCode,
    name,
    nameExtension,
    productNumber,
    unit,
    purchasePrice,
    salesPrice,
    salesDiscount,
    defaultSalesAccount,
    warehouseId: productWarehouseId,
    defaultVatClassId,
    _embedded
  } = product

  const combinedVatRate = getVatRateByVatType(combinedVatRates, vatType) || getCombinedVatRateWithClassId(combinedVatRates, defaultVatClassId)

  const price = salesPrice || product.price || 0
  const vatPrice = price * (1 + combinedVatRate.rate / 100)

  const discount = salesDiscount || 0
  const primaryPrice = isVatPrimary ? vatPrice : price
  const secondaryPrice = !isVatPrimary ? vatPrice : price
  const primarySum = (primaryPrice * count) * (1 - (discount / 100))
  const secondarySum = (secondaryPrice * count) * (1 - (discount / 100))

  const { productTasks = [] } = _embedded || {}
  const defaultProductTask = productTasks[0] || {}
  const { packetIndustry = {} } = defaultProductTask._embedded || {}

  const productTaskId = defaultProductTask.id || null
  const normHourPrice = packetIndustry.normHourPrice || null
  const normHour = defaultProductTask.normHour || null
  const normHourPriceSum = packetIndustry.normHourPrice * normHour * count || null
  const productTaskPacketIndustryName = packetIndustry.name || null
  const productTaskDescription = defaultProductTask.description || null

  const initialWorkRow = getInitialWorkRowData({
    ...workRowModel,
    workId,
    warehouseId: productWarehouseId || warehouseId || null,
    denominationId: denominationId ?? productDenominationId ?? defaultDenominationId,
    costCentreId: defaultCostCentreId,
    productNumber,
    vatPrice,
    price,
    [getPrimaryPriceSumField({ isVatPrimary })]: round8decimals(primarySum),
    [getSecondaryPriceSumField({ isVatPrimary })]: round8decimals(secondarySum),
    vatClassId: combinedVatRate.vatClassId,
    vatCodeId: combinedVatRate.id,
    deliveryDate: now(),
    name,
    nameExtension,
    unit,
    purchasePrice: purchasePrice != null ? round8decimals(purchasePrice) : null,
    productTaskId,
    normHour,
    normHourPrice,
    normHourPriceSum,
    productTaskPacketIndustryName,
    productTaskDescription,
    discount,
    productId,
    productLineCode,
    accountNumber: overrideAccountNumber || defaultSalesAccount,
    preventInvoicing: !!isFixedPrice
  })
  return initialWorkRow
}

// NOTE: fields are not used nor tested properly. Be careful
export const calculateWorkRowPrice = (work, combinedVatRates) => {
  const { isVatPrimary, vatType } = work
  const vatTypeCombinedVatRate = getVatRateByVatType(combinedVatRates, vatType)

  const calculateVat = (vatClassId, deliveryDate, primaryPrice) => {
    const combinedVatRate = vatTypeCombinedVatRate || getCombinedVatRateWithClassId(combinedVatRates, vatClassId, deliveryDate)
    if(!combinedVatRate) {
      return primaryPrice
    }
    const rate = combinedVatRate.rate / 100
    const vatFormula = isVatPrimary ? (1 / (1 + rate)) : (1 + rate)
    return primaryPrice * vatFormula
  }

  const primaryPriceField = getPrimaryPriceField({ isVatPrimary })
  const secondaryPriceField = getSecondaryPriceField({ isVatPrimary })

  return workRow => {
    if(nonPriceWorkRowTypes.includes(workRow.type)) {
      return workRow
    }
    const {
      deliveryDate,
      vatClassId,
      count,
      discount,
      purchasePrice,
      normHour,
      normHourPrice
    } = workRow

    const priceData = {}
    const primaryPrice = workRow[primaryPriceField]

    if(primaryPrice == null) {
      priceData[primaryPriceField] = 0
      priceData[secondaryPriceField] = 0
      priceData.priceSum = 0
      priceData.vatPriceSum = 0
    } else {
      priceData[primaryPriceField] = primaryPrice
    }

    const safeCount = count || 0
    const safeDiscount = getDiscountCoefficient(discount)

    priceData[secondaryPriceField] = round8decimals(calculateVat(vatClassId, deliveryDate, primaryPrice))

    const priceSum = (safeCount * priceData.price || 0) * safeDiscount
    priceData.priceSum = round2decimals(priceSum)
    priceData.vatPriceSum = round2decimals((safeCount * priceData.vatPrice || 0) * safeDiscount)

    const discountPrice = (priceData.price || 0) * safeDiscount
    const grossProfit = purchasePrice != null ? round2decimals((discountPrice - purchasePrice) * safeCount) : null
    priceData.grossProfit = purchasePrice != null ? round2decimals(grossProfit) : null
    priceData.grossProfitPercentage = calculateGrossProfitPercentage(grossProfit, purchasePrice, count)
    priceData.normHourPriceSum = normHour != null && normHourPrice != null ? round2decimals(normHour * normHourPrice * count) : null
    return {
      ...workRow,
      ...priceData
    }
  }
}

export const useCreateWorkRowFromProduct = work => {
  const combinedVatRates = useCombinedVatRates()
  const overrideAccountNumber = useSelector(state => getAccountNumberByVatType(state, work.vatType))
  const [getProductDenomination] = useLazyGetProductDenominationQuery()
  const denominationSystemType = useSelector(getDenominationSystemType)

  return async(product, workRow) => {
    const { data } = denominationSystemType === denominationSystemTypes.DENOMINATION_TYPE_LVIS ? await getProductDenomination(product.id) : {}
    return createWorkRowFromProduct(workRow, product, combinedVatRates, work, overrideAccountNumber, data?.record?.id)
  }
}

export const getSubTotal = (rows, index) => {
  let rowsUntilReversed = rows.slice(0, index)
    .reverse()
    .filter(row => !row.preventInvoicing && row.type !== workRowTypes.WORK_ROW_TYPE_COMMENT)
  const nextSectionStartIndex = rowsUntilReversed.findIndex(row => row.type === workRowTypes.WORK_ROW_TYPE_SUB_TOTAL || row.type === workRowTypes.WORK_ROW_TYPE_SUB_HEADER)
  if(nextSectionStartIndex !== -1) {
    rowsUntilReversed = rowsUntilReversed.slice(0, nextSectionStartIndex)
  }
  return rowsUntilReversed.reduce((acc, row) => {
    acc.vatPriceSum += row.vatPriceSum ?? 0
    acc.priceSum += row.priceSum ?? 0
    return acc
  }, { vatPriceSum: 0, priceSum: 0 })
}

export const updateSubTotalRowPrices = invoiceRows => {
  const hasWorkSubTotalRows = invoiceRows.some(row => row.type === workRowTypes.WORK_ROW_TYPE_SUB_TOTAL && !row.previewWorkTotalRow)
  if(!hasWorkSubTotalRows) {
    return invoiceRows
  } else {
    const invoiceRowsWithSubTotalRows = []
    invoiceRows.forEach((row, index) => {
      if(row.type === workRowTypes.WORK_ROW_TYPE_SUB_TOTAL && !row.previewWorkTotalRow) {
        return invoiceRowsWithSubTotalRows.push({ ...row, ...getSubTotal(invoiceRows, index), name: row.name || 'Välisumma' })
      } else {
        return invoiceRowsWithSubTotalRows.push(row)
      }
    })
    return invoiceRowsWithSubTotalRows
  }
}
