import { toast } from 'react-toastify'

const addNotification = (message, level, opts = {}) => {
  switch(level) {
    case 'error':
      return toast.error(message, { ...opts })
    case 'warning':
      return toast.warning(message, { ...opts })
    case 'info':
      return toast.info(message, { ...opts })
    default:
      return toast.success(message, { autoClose: 1000, ...opts })
  }
}

export const addErrorNotification = (message, opts = {}) => {
  return addNotification(message, 'error', { autoClose: 5000, ...opts })
}

export const addSuccessNotification = (message, opts = {}) => {
  return addNotification(message, 'success', opts)
}

export const addWarningNotification = (message, opts = {}) => {
  return addNotification(message, 'warning', { autoClose: 5000, ...opts })
}

export const addInfoNotification = (message, opts = {}) => {
  return addNotification(message, 'info', { autoClose: 5000, ...opts })
}

export const updateNotification = (toastId, toastOptions = {}) => {
  return toast.update(toastId, toastOptions)
}

export const removeNotification = toastId => {
  return toast.dismiss(toastId)
}
